import firebase from "firebase";
import "firebase/database";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB3l88lzBpla_Pq2xtbdkoG380AelNLloU",
  authDomain: "movi-delivery.firebaseapp.com",
  databaseURL: "https://movi-delivery.firebaseio.com",
  projectId: "movi-delivery",
  storageBucket: "movi-delivery.appspot.com",
  messagingSenderId: "755879797991",
  appId: "1:755879797991:web:6708307538c4170c8aae42",
  serviceAccountId:
    "http://755879797991-8dcdfb6crfmegu8v2npk3i72if9r622j.apps.googleusercontent.com/",
};

let myApp;

if (!firebase.apps.length) {
  myApp = firebase.initializeApp(firebaseConfig);
} else {
  myApp = firebase.app();
}

export default myApp;

export const auth = myApp.auth();


export const database = firebase.database();

export const moviDb = firebase.firestore();

export const getUsers = database.ref(`/users`);

// export const admininitialize = admin.initializeApp({
//   serviceAccountId:
//     "http://755879797991-8dcdfb6crfmegu8v2npk3i72if9r622j.apps.googleusercontent.com/",
// });
// console.log("admin", admin.auth());
