import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { getUsers, moviDb, auth } from "../firebase/index";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import * as actions from "../actions/Products";
import Divider from "../assets/Sort.svg";
import SortIcons from "./common/SortIcons";
import { FaEdit, FaEye, FaFilter, FaTimes } from "react-icons/fa";
import { Drawer, makeStyles, TablePagination } from "@material-ui/core";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import Loader from "../common/loader/loader";
import {
  getOrdersList,
  getOrdersListByStatus,
  updateOrder,
} from "../actions/Orders";
import ViewOrder from "./ViewOrder";
import Select from "react-select";
import projectContext from "../context/App.Context";
import axios from "axios";
import { apiKey, ckcs, siteUrl } from "../actions/common/ApiLinks";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import OrderStatus from "./OrderStatus";

function Orders(props) {
  const { sidebarStatus } = useContext(projectContext);
  const history = useHistory();
  const [ordersList, setOrdersList] = useState([]);
  const [sortedOrdersList, setSortedOrdersList] = useState([]);
  const [sorting, setSorting] = useState({
    field: "id",
    order: "asc",
  });
  const [up, setUp] = useState(true);
  const [state, setState] = React.useState({
    left: false,
  });
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [isviewOrder, setIsViewOrder] = useState(false);
  const [viewingItem, setViewingItem] = useState({});
  const [statusFilter, setStatusFilter] = useState(null);
  const [refreshItems, setRefreshItems] = useState(false);

  useEffect(() => {
    getOrdersList(setIsLoading, setOrdersList, setSortedOrdersList);
  }, [refreshItems]);
  const handleFilterSelectedType = (type) => {
    setStatusFilter(type);
    getOrdersListByStatus(
      setIsLoading,
      setOrdersList,
      setSortedOrdersList,
      "status",
      type.value
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const headerData = [
    {
      name: "Order",
      field: "id",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Order Date",
      field: "order_date",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "status",
      field: "order_status",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Total",
      field: "total",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Fulfillment Center",
      field: "fullfilment_center",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Delivery Date",
      field: "delivery_date",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Actions",
      field: "actions",
      numeric: true,
      sortable: false,
    },
  ];
  const useStyles = makeStyles({
    list: {
      width: 250,
      padding: "20px",
    },
    fullList: {
      width: "auto",
    },
  });
  const classes = useStyles();
  const filterItems = [
    "id",
    "first_name",
    "total",
    "last_name",
    "fullfilment_center",
    "order_status",
    "delivery_date",
    "order_date",
  ];
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const arr = [...sortedOrdersList];
    let newArray = arr.filter((item) => {
      return filterItems.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    setOrdersList(newArray);
  };
  const renderCreatedDate = (created_date) => {
    let a = new Date(created_date).toString();
    let b = [a.split(" ")];
    return `${b[0][1]} ` + `${b[0][2]} ` + `${b[0][3]}`;
  };
  const renderStatus = (status) => {
    switch (status) {
      case "processing":
        return (
          <div className="statusButton">
            <p className="processing-btn">
              processing{" "}
              <FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              />
            </p>
          </div>
        );
      case "cancelled":
        return (
          <div className="statusButton">
            <p className="cancelled-btn">Cancelled <FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              /></p>
          </div>
        );
      case "outfordelivery":
        return (
          <div className="statusButton">
            <p className="cancelled-btn">Out for Delivery <FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              /></p>
          </div>
        );
      case "on-hold":
        return (
          <div className="statusButton">
            <p className="onhold-btn">On hold<FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              /></p>
          </div>
        );
      case "assigned":
        return (
          <div className="statusButton">
            <p className="assigned-btn">Assigned<FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              /></p>
          </div>
        );
      case "trash":
        return (
          <div className="statusButton">
            <p className="assigned-btn">Trash<FaEdit
                title="Edit"
                style={{
                  cursor: "pointer",
                  color: "#2E8B57",
                }}
              /></p>
          </div>
        );
      case "packing":
        return (
          <div className="statusButton">
            <p className="packing-btn">Packing</p>
          </div>
        );
      case "customer-cancel":
        return (
          <div className="statusButton">
            <p className="cancelled-btn"> Cancelled By Customer</p>
          </div>
        );
      case "refunded":
        return (
          <div className="statusButton">
            <p className="cancelled-btn">Refunded</p>
          </div>
        );
      case "pending":
        return (
          <div className="statusButton">
            <p className="cancelled-btn">Pending Payment</p>
          </div>
        );
      case "completed":
        return (
          <div className="statusButton">
            <p className="completed-btn">Completed</p>
          </div>
        );
      case "delivered":
        return (
          <div className="statusButton">
            <p className="delivered-btn">Delivered</p>
          </div>
        );
      default:
        return "";
    }
  };
  const commentsData = useMemo(() => {
    let computedComments = ordersList;
    if (sorting.field) {
      computedComments = computedComments.sort((a, b) => {
        if (a[sorting.field] < b[sorting.field]) {
          return sorting.order === "asc" ? 1 : -1;
        } else if (a[sorting.field] > b[sorting.field]) {
          return sorting.order === "asc" ? -1 : 1;
        }
      });
    }
  }, [sorting]);
  const statuses = [
    { value: "processing", label: "Processing" },
    { value: "cancelled", label: "Cancelled" },
    { value: "on-hold", label: "On Hold" },
    { value: "trash", label: "Trash" },
    { value: "assigned", label: "Assigned" },
    { value: "packing", label: "Packing" },
    { value: "customer-cancel", label: "Customer Cancel" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "delivered", label: "Delivered" },
    { value: "refunded", label: "Refunded" },
    { value: "outfordelivery", label: "Out for Delivery" },
  ];
  const confirmedToUpdateStatus = (id) => {
    const data = {
      status: "on-hold",
    };
    setIsLoading(true);
    axios
      .put(`${siteUrl}orders/${id}?${ckcs}&api_key=${apiKey}`, data)
      .then((res) => {
        setIsLoading(false);
        history.push(`/orders/${id}`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <div className="container-scroller">
      <ToastContainer />
      {isLoading ? <Loader /> : ""}
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                {isviewOrder ? (
                  <ViewOrder
                    setIsViewOrder={setIsViewOrder}
                    order={viewingItem}
                    setLoading={setIsLoading}
                    refreshItems={refreshItems}
                    setRefreshItems={setRefreshItems}
                  />
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <h4
                        className="card-title header-filter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Orders List
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          className="marginTop-10"
                        >
                          <FaFilter
                            style={{ cursor: "pointer", marginRight: "30px" }}
                            onClick={toggleDrawer("left", true)}
                            className="filterIcon"
                          />
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            style={{ width: "200px" }}
                          >
                            {
                              <div
                                className={clsx(classes.list, {
                                  [classes.fullList]:
                                    "left" === "top" || "left" === "bottom",
                                })}
                              >
                                <h4>Filter By Status</h4>
                                <Select
                                  options={statuses}
                                  placeholder="Select status"
                                  className="abc"
                                  name="status"
                                  value={statusFilter}
                                  onChange={handleFilterSelectedType}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <button
                                    className="filter_buttons_submit"
                                    onClick={() => {
                                      setStatusFilter(null);
                                      setRefreshItems(!refreshItems);
                                    }}
                                  >
                                    Clear Filter
                                  </button>
                                  <button
                                    className="filter_buttons_clear"
                                    onClick={toggleDrawer("left", false)}
                                  >
                                    close
                                  </button>
                                </div>
                              </div>
                            }
                          </Drawer>
                          <div
                            className="input-group"
                            style={{ width: "400px" }}
                          >
                            <div
                              className="input-group-prepend hover-cursor"
                              id="navbar-search-icon"
                            >
                              <span className="input-group-text" id="search">
                                <i className="icon-search" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="navbar-search-input"
                              placeholder="Search now"
                              aria-label="search"
                              aria-describedby="search"
                              value={searchValue}
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {headerData.map((item, index) => (
                                <th key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.name}
                                    {item.sortable ? (
                                      <SortIcons
                                        up={up}
                                        setUp={setUp}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        item={item}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {ordersList?.length !== 0 ? (
                              ordersList
                                ?.slice(
                                  (page + 1 - 1) * rowsPerPage,
                                  (page + 1) * rowsPerPage
                                )
                                .map((order, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="py-1" data-label="Order">
                                        #{order.id} {order.first_name}{" "}
                                        {order?.last_name}
                                      </td>
                                      <td data-label="Order Date">
                                        {order?.order_date}
                                      </td>
                                      <td data-label="Order Status">
                                        <OrderStatus
                                          status={order?.order_status}
                                          refreshItems={refreshItems}
                                          setRefreshItems={setRefreshItems}
                                          order={order}
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      </td>
                                      <td data-label="Total">{order?.total}</td>
                                      <td data-label="Center">
                                        {order?.fullfilment_center}
                                      </td>
                                      <td data-label="Delivery Date">
                                        {order?.delivery_date}
                                      </td>
                                      <td data-label="Actions">
                                        &nbsp;
                                        <a
                                          href={order.permalink}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{ color: "#2E8B57" }}
                                        >
                                          <FaEye
                                            style={{ cursor: "pointer" }}
                                            title="Preview"
                                            onClick={() => {
                                              setIsLoading(true);
                                              let url = `${siteUrl}orders/${order.id}?${ckcs}&api_key=${apiKey}`;
                                              axios
                                                .get(url)
                                                .then((res) => {
                                                  setViewingItem(res.data);
                                                  setIsLoading(false);
                                                  setIsViewOrder(true);
                                                })
                                                .catch((err) => {
                                                  setIsLoading(false);
                                                  console.log(err);
                                                });
                                            }}
                                          />
                                        </a>
                                        &nbsp;&nbsp;&nbsp;
                                        <FaEdit
                                          title="Edit"
                                          style={{
                                            cursor: "pointer",
                                            color: "#2E8B57",
                                          }}
                                          onClick={() => {
                                            Swal.fire({
                                              title:
                                                "Do you want to edit the Order?",
                                              text: "Order status will change to ON HOLD until the order edit is done.",
                                              showDenyButton: true,
                                              showCancelButton: false,
                                              confirmButtonText: `Proceed`,
                                              denyButtonText: `Cancel`,
                                              confirmButtonColor: "#39f",
                                              icon: "info",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                localStorage.setItem(
                                                  "status",
                                                  order?.order_status
                                                );
                                                confirmedToUpdateStatus(
                                                  order.id
                                                );
                                              } else if (result.isDenied) {
                                                history.push(
                                                  `/orders/${order.id}`
                                                );
                                              }
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <td colSpan={5}>
                                <p
                                  className="d-flex align-items-center"
                                  style={{ justifyContent: "center" }}
                                >
                                  {isLoading
                                    ? "Loading..."
                                    : "No Orders Available"}
                                </p>
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <TablePagination
                        component="div"
                        count={ordersList?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 15, 20, 25]}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:../../partials/_footer.html */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a href="https://myonlinevipani.com" target="_blank">
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    productList: state.Products.productsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProductList: () => dispatch(actions.getClientList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
