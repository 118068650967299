import { combineReducers } from "redux";
import { Login } from "./Login";
import { Products } from "./Products";
import { Sidebar } from "./Sidebar";

export default combineReducers({
  Login,
  Products,
  Sidebar,
});
