const init = {
  productsList: [],
};

export const Products = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_PRODUCTS_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
