import React, { Component, useEffect } from "react";
import "../../App.css";
import { FaTimes } from 'react-icons/fa';
import { getUsers, moviDb, auth } from "../../firebase/index";
import Progress from "./Progress";
import Loader from "../../common/loader/loader";
import Select from "react-select";


const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};
const PaymentMethods = [
  { value: 'Google Pay', label: 'Google Pay' },
  { value: 'Cash On Delivery', label: 'Cash On Delivery' },
  { value: 'PayUMoney', label: 'PayUMoney' },
  { value: 'Razorpay', label: 'Razorpay' },
  { value: 'Net banking', label: 'Net banking' },
  { value: 'Paypal', label: 'Paypal' },
  { value: 'Micro ATM', label: 'Micro ATM' },
  { value: 'Other', label: 'Other' }
]
const PaymentStatus = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Unpaid', label: 'Unpaid' }
]

class AddPayment extends Component {
  constructor(props) {
    super(props);
    let editpayment = props.editpayment;
    let isEdit = props.isEdit;
    this.state = {
      isEdit: props.isEdit,
      editpayment: props.editpayment,
      paymentId:
        isEdit && editpayment.order_number ? editpayment.order_number : null,
      order_date:
        isEdit && editpayment.order_date ? editpayment.order_date : null,
      delivery_date:
        isEdit && editpayment.delivery_date ? editpayment.delivery_date : null,
      customer: isEdit && editpayment.customer ? editpayment.customer : null,
      order_total:
        isEdit && editpayment.order_total ? editpayment.order_total : null,
      payment_method:
        isEdit && editpayment.payment_method
          ? this.renderMethod(editpayment.payment_method)
          : null,
      payment_status:
        isEdit && editpayment.payment_status
          ? this.renderStatus(editpayment.payment_status)
          : null,
      isOpen: false,
      progress: false,
      submitObj: {},
      isLoading: false,
      labelAddEdit: isEdit ? "Edit Payment" : "Add Payment",
      formErrors: {
        paymentId: "",
        customer: "",
        order_total: "",
        payment_method: "",
        payment_status: "",
        order_date: "",
        delivery_date: "",
      },
    };
  }
  renderMethod = (value) => {
    switch (value) {
      case "Google Pay":
        return { value: "Google Pay", label: "Google Pay" };
      case "Cash On Delivery":
        return { value: "Cash On Delivery", label: "Cash On Delivery" };
      case "PayUMoney":
        return { value: "PayUMoney", label: "PayUMoney" };
      case "Razorpay":
        return { value: "Razorpay", label: "Razorpay" };
      case "Net banking":
        return { value: "Net banking", label: "Net banking" };
      case "Paypal":
        return { value: "Paypal", label: "Paypal" };
      case "Micro ATM":
        return { value: "Micro ATM", label: "Micro ATM" };
      case "Other":
        return { value: "Other", label: "Other" };
      default:
        return "";
    }
  };
  renderStatus = (value) => {
    switch (value) {
      case "Paid":
        return { value: "Paid", label: "Paid" };
      case "Unpaid":
        return { value: "Unpaid", label: "Unpaid" };
      default:
        return "";
    }
  };
  handleClose = (e) => {
    this.state.isOpen = true;
    this.props.handleClose(this.state.isOpen);
  };

  handleFollowup = (e) => {
    this.state.checked = !this.state.checked;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.state.progress = true;
    if (formValid(this.state)) {
      this.state.submitObj = {
        customer: this.state.customer,
        delivery_date: this.state.delivery_date,
        order_date: this.state.order_date,
        order_number: this.state.paymentId,
        order_total: this.state.order_total,
        payment_method: this.state.payment_method.value,
        payment_status: this.state.payment_status.value,
      };
      if (!this.state.isEdit) {
        moviDb
          .collection("payments")
          .add(this.state.submitObj)
          .then((doc) => {
            const responseTodoItem = this.state.submitObj;
            responseTodoItem.id = doc.id;
            // console.log(responseTodoItem);
            this.props.handleCallLogChange(responseTodoItem);
            this.state.progress = false;
            this.props.getPayments();

          })
          .catch((err) => {
            console.error(err);
          });
        this.handleClose();
      } else {
        this.state.submitObj.id = this.state.editpayment.id;
        this.setState({isLoading: true})
        const document = moviDb
          .collection("payments")
          .doc(this.state.editpayment.id)
          .update(this.state.submitObj)
          .then((doc) => {
            this.setState({isLoading: false})
            this.props.getPayments();
            const responseTodoItem = this.state.submitObj;
            // console.log(responseTodoItem);
            this.props.handleCallLogChange(responseTodoItem);
            this.state.progress = false;
          })
          .catch((err) => {
            this.setState({isLoading: false})
            console.error(err);
          });
        this.handleClose();
      }
    } else {
      this.state.progress = false;
      let formErrors = { ...this.state.formErrors };
      formErrors.paymentId = "minimum 3 characaters required";
      formErrors.customer = "minimum 3 characaters required";
      formErrors.order_total = "minimum 3 characaters required";
      formErrors.payment_method = "minimum 3 characaters required";
      formErrors.payment_status = "minimum 3 characaters required";
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "paymentId":
        formErrors.paymentId =
          value?.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "customer":
        formErrors.customer =
          value?.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "order_total":
        formErrors.order_total =
          value?.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "payment_method":
        formErrors.payment_method =
          value?.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "payment_status":
        formErrors.payment_status =
          value?.length < 3 ? "minimum 3 characaters required" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };
  handleSelectmethod = (method) => {
    this.setState({ payment_method: method });
  };
  handleSelectstatus = (status) => {
    this.setState({ payment_status: status });
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className="modal-wrapper">
        <div className="wrapper">
          <div className="form-wrapper">
            {this.state.isLoading ? <Loader /> : ''}

            <div class="modal-header">
              <div class="modal-title title-card">
                {this.state.labelAddEdit}
              </div>
              <FaTimes
                title="Close"
                style={{ cursor: "pointer" }}
                onClick={this.handleClose}
              />
            </div>
            <div class="modal-body">
              <form onSubmit={this.handleSubmit} noValidate>
                <label class="col-sm-4 col-form-label">Order Number</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Order number"
                    type="number"
                    name="paymentId"
                    value={this.state.paymentId}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.paymentId.length > 0 && (
                    <span className="errorMessage">{formErrors.paymentId}</span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Order Date</label>
                <div class="col-sm-8 textarea-class">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    placeholder="Order date"
                    name="order_date"
                    value={this.state.order_date}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.order_date.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.order_date}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Delivery Date</label>
                <div class="col-sm-8 textarea-class">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    placeholder="Delivery date"
                    name="delivery_date"
                    value={this.state.delivery_date}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.delivery_date.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.delivery_date}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Customer</label>
                <div class="col-sm-8 textarea-class">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Customer name"
                    name="customer"
                    value={this.state.customer}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.customer.length > 0 && (
                    <span className="errorMessage">{formErrors.customer}</span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Order Total(Inr)</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Order amount"
                    type="number"
                    name="order_total"
                    value={this.state.order_total}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.order_total.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.order_total}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Payment Method</label>
                <div class="col-sm-8">
                  {/* <input
                    class="form-control form-control-sm"
                    placeholder="Payment Method"
                    type="text"
                    name="payment_method"
                    value={this.state.payment_method}
                    noValidate
                    onChange={this.handleChange}
                  /> */}
                  <Select
                    options={PaymentMethods}
                    class="form-control form-control-sm"
                    placeholder="Payment method"
                    name="payment_method"
                    value={this.state.payment_method}
                    onChange={this.handleSelectmethod}
                  />
                  {formErrors.payment_method.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.payment_method}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Payment Status</label>
                <div class="col-sm-8">
                  <Select
                    options={PaymentStatus}
                    class="form-control form-control-sm"
                    placeholder="Payment status"
                    name="payment_status"
                    value={this.state.payment_status}
                    onChange={this.handleSelectstatus}
                  />
                  {formErrors.payment_status.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.payment_status}
                    </span>
                  )}
                </div>
                <div class="modal-footer col-sm-12">
                  <button class="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPayment;