import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsers, moviDb, auth } from "../firebase/index";
import emailjs from "emailjs-com";
import Loader from "../common/loader/loader";
import Swal from "sweetalert2";
import { otpEmails } from "../actions/common/ApiLinks";
import { dateTimeFormat } from "../actions/Login";
// import history from "../stores/history";

function Login(props) {
  const { history, location = {} } = props;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(false);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const requestedOtp = randomIntFromInterval(1000, 9999);

  const signIn = () => {
    if (userName && password) {
      setProgress(true);
      auth
        .signInWithEmailAndPassword(userName, password)
        .then((response) => {
          setProgress(false);
          const userJson = {
            login_name: response.user.displayName,
            email: response.user.email,
            photo: response.user.photoURL,
            phone: response.user.phoneNumber,
          };
          const otp = Math.floor(1000 + Math.random() * 9000);
          const templateParams = {
            to: `${otpEmails}`,
            from_name: "MyOnlineVipani",
            account_name: userJson.email,
            message: requestedOtp,
            time_stamp: dateTimeFormat(),
          };
          localStorage.setItem("userJson", JSON.stringify(userJson));

          emailjs.send(
            process.env.REACT_APP_SERVICEID,
            "template_vo36n5d",
            templateParams,
            process.env.REACT_APP_USERID
          );
          let expirationDate = new Date(new Date().getTime() + 60000 * 1);
          const otpExpire = {
            otp: requestedOtp,
            expirationTime: expirationDate.toISOString(),
          };
          sessionStorage.setItem("myonlineotp", JSON.stringify(otpExpire));
          history.push({ pathname: "/otp_screen", state: { otp: true } });
        })
        .catch((err) => {
          setProgress(false);
          Swal.fire({
            text: err.message,
            icon: "error",
          }).then((res) => {
            if (res.isConfirmed) {
              setLoginStatus(0);
              setErrorMessage("");
            }
          });
          console.log(err);
        });
    } else if (!userName && !password) {
      setLoginStatus(2);
      setErrorMessage("Please enter Email & Password");
    } else if (!userName) {
      setLoginStatus(2);
      setErrorMessage("Please enter Email Id");
    } else if (!password) {
      setLoginStatus(2);
      setErrorMessage("Please Enter Password");
    }
  };

  useEffect(() => {
    let userLoggedIn = localStorage.getItem("myonlineLogin");
    if (userLoggedIn) {
      history.push("/");
    }
  }, []);

  return (
    <div className="container-scroller">
      {progress && <Loader />}
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/logo.jpg"}
                    alt="logo"
                  />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                {loginStatus ? (
                  <div
                    className={`alert ${
                      loginStatus === 2 ? "alert-danger" : null
                    }`}
                    role="alert"
                  >
                    {loginStatus === 2 ? errorMessage : null}
                  </div>
                ) : (
                  ""
                )}
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Username"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <a
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={() => signIn()}
                      //   href="../../index.html"
                    >
                      SIGN IN
                    </a>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" />
                        Keep me signed in
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
