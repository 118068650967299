import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import projectContext from "../context/App.Context";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import OrderDashboard from "./OrderDashboard";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { moviDb } from "../firebase";
import HolidaysTable from "./HolidaysTable";
import AddHoliday from "./common/AddHoliday";
import axios from 'axios';
import Loader from "../common/loader/loader";

function Holidays() {
  const { sidebarStatus } = useContext(projectContext);
  const [holidaysList, setHolidaysList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const togglePopup = (open) => {
    setIsOpen(!isOpen);
    setIsEdit(false);

    if (isOpen) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  };
  useEffect(() => {
    getHolidaysList();
  }, []);
  const getHolidaysList = () => {
    setIsLoading(true);
    axios
      .get(
        "https://firestore.googleapis.com/v1/projects/my-online-vipani-react-native/databases/(default)/documents/holidays"
      )
      .then((response) => {
        setIsLoading(false);
        setHolidaysList(response.data?.documents);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleCallLogChange = (e) => {
    setIsEdit(false);
  };
  return (
    <div className="container-scroller">
      <Header />
      {isLoading ? <Loader /> : ''}
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            {isOpen && (
              <AddHoliday
                isEdit={false}
                editpayment={{}}
                handleClose={togglePopup}
                handleCallLogChange={handleCallLogChange}
              />
            )}
            <HolidaysTable togglePopup={togglePopup} holidaysList={holidaysList} />
          </div>

          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a href="https://myonlinevipani.com" target="_blank">
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
