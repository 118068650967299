import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions/Sidebar";
import projectContext from "../../context/App.Context";
import { AuthProvider } from "../../context/AuthContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useHistory } from "react-router";

function Sidebar(props) {
  const { sidebarStatus } = useContext(projectContext);
  const changeNaveItemActive = (active) => {
    props.changeSidebarInfo("navActiveItem", active);
  };
  const [collapse, setCollapse] = useState(false);
  const [collapseDel, setCollapseDel] = useState(false);

  const history = useHistory();

  return (
    <>
      {sidebarStatus ? (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li
              className={`nav-item${
                props.navActiveItem === "dashboard" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("dashboard")}
            >
              <Link className="nav-link" to="/">
                <i className="icon-grid menu-icon" />
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "products" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("products")}
            >
              <Link className="nav-link" to="/products">
                <i className="mdi mdi-menu" />
                <span className="menu-title">Products</span>
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "reports" ||
                props.navActiveItem === "salesbyproducts" ||
                props.navActiveItem === "salessummary" ||
                props.navActiveItem === "salesbystaff"
                  ? " active"
                  : ""
              }`}
              onClick={(e) => {
                changeNaveItemActive("reports");
                setCollapse(!collapse);
              }}
            >
              <div className="nav-link">
                <i className="mdi mdi-chart-bar" />
                <span className="menu-title">Reports</span>
                {collapse ? (
                  <FaChevronUp color="white" style={{ marginLeft: "30px" }} />
                ) : (
                  <FaChevronDown color="white" style={{ marginLeft: "30px" }} />
                )}
              </div>
              {collapse ? (
                <>
                  <Link className="nav-link" to="/salessummary">
                    <span className="menu-title" style={{ marginLeft: "30px" }}>
                      Sales Summary
                    </span>
                  </Link>
                  <Link className="nav-link" to="/salesbyproducts">
                    <span className="menu-title" style={{ marginLeft: "30px" }}>
                      Sales by Product
                    </span>
                  </Link>
                  <Link className="nav-link" to="/salesbystaff">
                    <span className="menu-title" style={{ marginLeft: "30px" }}>
                      Sales by Staff
                    </span>
                  </Link>
                </>
              ) : (
                ""
              )}
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "orders" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("orders")}
            >
              <Link className="nav-link" to="/orders">
                <i className="mdi mdi-shopping" />
                <span className="menu-title">Orders</span>
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "calllogs" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("calllogs")}
            >
              <Link className="nav-link" to="/calllogs">
                <i className="mdi mdi-phone" />
                <span className="menu-title">Call Logs</span>
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "payments" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("payments")}
            >
              <Link className="nav-link" to="/payments">
                <i className="mdi mdi-cash-multiple" />
                <span className="menu-title">Payments</span>
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "delivery schedule" ||
                props.navActiveItem === "holidays" ||
                props.navActiveItem === "delivery settings"
                  ? " active"
                  : ""
              }`}
              onClick={(e) => {
                changeNaveItemActive("delivery schedule");
                setCollapseDel(!collapseDel);
              }}
            >
              <div className="nav-link">
                <i className="mdi mdi-truck" />
                <span className="menu-title">Delivery Schedule</span>
                {collapseDel ? (
                  <FaChevronUp color="white" style={{ marginLeft: "2px" }} />
                ) : (
                  <FaChevronDown color="white" style={{ marginLeft: "2px" }} />
                )}
              </div>
              {collapseDel ? (
                <>
                  <Link className="nav-link" to="/holidays">
                    <span className="menu-title" style={{ marginLeft: "30px" }}>
                      Holidays
                    </span>
                  </Link>
                  <Link className="nav-link" to="/deliverysettings">
                    <span className="menu-title" style={{ marginLeft: "30px" }}>
                      Delivery Settings
                    </span>
                  </Link>
                </>
              ) : (
                ""
              )}
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "payumoney" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("payumoney")}
            >
              <a
                className="nav-link"
                href="https://www.payu.in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-credit-card" />
                <span className="menu-title">PayUmoney</span>
              </a>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "razorpay" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("razorpay")}
            >
              <a
                className="nav-link"
                href="https://razorpay.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-contactless-payment" />
                <span className="menu-title">Razorpay</span>
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <nav className="sidebar width-80 sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li
              className={`nav-item${
                props.navActiveItem === "dashboard" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("dashboard")}
              title="Dashboard"
            >
              <Link className="nav-link" to="/">
                <i className="icon-grid menu-icon" />
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "products" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("products")}
              title="Products"
            >
              <Link className="nav-link" to="/products">
                <i className="mdi mdi-menu" />
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "reports" ||
                props.navActiveItem === "salesbyproducts" ||
                props.navActiveItem === "salessummary" ||
                props.navActiveItem === "salesbystaff"
                  ? " active"
                  : ""
              }`}
              onClick={(e) => {
                changeNaveItemActive("reports");
                setCollapse(!collapse);
              }}
            >
              <div className="nav-link">
                <i className="mdi mdi-chart-bar" />
                {collapse ? (
                  <FaChevronUp color="white" style={{ marginLeft: "30px" }} />
                ) : (
                  <FaChevronDown color="white" style={{ marginLeft: "30px" }} />
                )}
              </div>
              {collapse ? (
                <>
                  <Link
                    to="/salessummary"
                    className="nav-link"
                    title="Sales Summary"
                  >
                    <i class="mdi mdi-chart-line"></i>
                  </Link>
                  <Link
                    className="nav-link"
                    to="/salesbyproducts"
                    title="Sales by Products"
                  >
                    <i class="mdi mdi-chart-pie"></i>
                  </Link>
                  <Link
                    className="nav-link"
                    to="/salesbystaff"
                    title="Sales by Staff"
                  >
                    <i class="mdi mdi-account-check"></i>
                  </Link>
                </>
              ) : (
                ""
              )}
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "orders" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("orders")}
              title="Orders"
            >
              <Link className="nav-link" to="/orders">
                <i className="mdi mdi-shopping" />
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "calllogs" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("calllogs")}
              title="Call Logs"
            >
              <Link className="nav-link" to="/calllogs">
                <i className="mdi mdi-phone" />
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "payments" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("payments")}
              title="Payments"
            >
              <Link className="nav-link" to="/payments">
                <i className="mdi mdi-cash-multiple" />
              </Link>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "delivery schedule" ||
                props.navActiveItem === "holidays" ||
                props.navActiveItem === "delivery settings"
                  ? " active"
                  : ""
              }`}
              onClick={(e) => {
                changeNaveItemActive("delivery schedule");
                setCollapseDel(!collapseDel);
              }}
            >
              <div className="nav-link">
                <i className="mdi mdi-truck" />
                {collapse ? (
                  <FaChevronUp color="white" style={{ marginLeft: "30px" }} />
                ) : (
                  <FaChevronDown color="white" style={{ marginLeft: "30px" }} />
                )}
              </div>
              {collapseDel ? (
                <>
                  <Link
                    to="/holidays"
                    className="nav-link"
                    title="Holidays"
                  >
                    <i class="mdi-calendar-multiple"></i>
                  </Link>
                  <Link
                    className="nav-link"
                    to="/deliverysettings"
                    title="Delivery Settings"
                  >
                    <i class="mdi mdi-settings"></i>
                  </Link>
                </>
              ) : (
                ""
              )}
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "payumoney" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("payumoney")}
              title="Pay U Money"
            >
              <a
                className="nav-link"
                href="https://www.payu.in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-credit-card" />
              </a>
            </li>
            <li
              className={`nav-item${
                props.navActiveItem === "razorpay" ? " active" : ""
              }`}
              onClick={(e) => changeNaveItemActive("razorpay")}
              title="RazorPay"
            >
              <a
                className="nav-link"
                href="https://razorpay.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-contactless-payment" />
              </a>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    navActiveItem: state.Sidebar.navActiveItem,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSidebarInfo: (f, e) => dispatch(actions.changeSidebarInfo(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
