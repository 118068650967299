import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Vipani from "./Vipani";


ReactDOM.render(
  <Vipani />,
  document.getElementById("root")
);
