import React from 'react';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { useState } from 'react';

const SortIcons = ({ setSorting, item }) => {
  const [isSorting, setIsSorting] = useState(false)
  return (
    <div className="sortIcons">
      {!isSorting ? (
        <ArrowDownwardOutlinedIcon onClick={() => {
          setIsSorting(!isSorting);
          setSorting({ field: item.field, order: 'asc' })
        }} />
      ) : (
        <ArrowUpwardOutlinedIcon onClick={() => {
          setIsSorting(!isSorting);
          setSorting({ field: item.field, order: 'desc' })
        }} />
      )}
    </div>
  );
};

export default SortIcons;