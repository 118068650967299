import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editor = ({ setEditorValue, editorValue, editProduct }) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data="<p></p>"
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorValue(data);
        }}
        onReady={(editor) => {
          if(editProduct){
          editor.setData(`<p>${editorValue}</p>`)
          }else{
          editor.setData(`<p></p>`)
          }
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
    </div>
  );
};

export default Editor;
