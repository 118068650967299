import React from "react";
 import "../../App.css";
const Progress = props => {
  return (
    <div className="spinner-grow modal-wrapper progress" role="status">
      <span class="visually-hidden">Loading...</span>                     
     </div>
  );
};
 
export default Progress;