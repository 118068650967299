import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Login from "./components/Login";

import * as actions from "./actions/Sidebar";
import { getUsers, moviDb, admininitialize } from "./firebase/index";
import Products from "./components/Products";
import Orders from "./components/Orders";
import Dashboard from "./components/Dashboard";
import OtpScreen from "./components/OtpScreen";
import PropsRoute from "./PropsRoute";
import CallLogs from "./components/CallLogs";
import Payments from "./components/Payments";
import EditOrder from "./components/EditOrder";
import SalesReports from "./components/SalesReports";
import SalesByProducts from "./components/SalesByProducts";
import salesByStaff from "./components/salesByStaff";
import DeliverySettings from "./components/DeliverySettings";
import Holidays from "./components/DeliverySchedule";

const Routes = (props) => {
  
  return (
    <Fragment>
      <ScrollTop />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/otp_screen" component={OtpScreen} />
        <PropsRoute exact path="/" component={Dashboard} />
        <PropsRoute exact path="/products" component={Products} />
        <PropsRoute exact path="/orders" component={Orders} />
        <PropsRoute exact path="/orders/:OrderId" component={EditOrder} />
        <PropsRoute exact path="/calllogs" component={CallLogs} />
        <PropsRoute exact path="/payments" component={Payments} />
        <PropsRoute exact path="/salessummary" component={SalesReports} />
        <PropsRoute exact path="/salesbyproducts" component={SalesByProducts} />
        <PropsRoute exact path="/salesbystaff" component={salesByStaff} />
        <PropsRoute exact path="/holidays" component={Holidays} />
        <PropsRoute exact path="/deliverysettings" component={DeliverySettings} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  changeSidebarInfo: (f, e) => dispatch(actions.changeSidebarInfo(f, e)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
