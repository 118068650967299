import React from "react";
import BaseApp from "./App";
import { BrowserRouter as Router, useHistory,  } from "react-router-dom";

const Vipani = () => {
  const history = useHistory();
  return (
    <Router history={history}>
      <BaseApp />
    </Router>
  );
};

export default Vipani;
