import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./components/Login";

function PropsRoute(props) {
  const { component: Component, ...rest } = props;
  let stat = localStorage.getItem("myonlineLogin");
  console.log(stat);

  return (
    <Route
      {...rest}
      render={(props) =>
        stat ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PropsRoute);
