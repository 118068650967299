import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import Select from "react-select";
import Swal from "sweetalert2";
import { ckcs, siteUrl } from "../actions/common/ApiLinks";

const RefundItem = ({
  item,
  index,
  currentOrderDetails,
  OrderId,
  setLoading,
  refreshItems,
  setRefreshItems,
  shippingMethods,
}) => {
  const deleteRefundItem = () => {
    setLoading(true)
    let url = `${siteUrl}orders/${OrderId}/refunds/${item.id}?force=true&${ckcs}`
    axios.delete(url).then(res =>{
      setLoading(false)
      Swal.fire({
        text: 'successfully deleted this refund',
        allowOutsideClick: false,
        icon: 'success',
      }).then(res =>{
        if(res.isConfirmed){
          setRefreshItems(!refreshItems);
        }
      })
    }).catch((err)=> Swal.fire({
      title: err?.response?.message,
      icon: 'error'
    }))
  }
  return (
    <tr key={index}>
      <td>
        <p>
          <FcRefresh /> Refund: #{item.id}
        </p>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td>{item.total}</td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        &nbsp;&nbsp;&nbsp;
        <FaTrashAlt
          style={{ cursor: "pointer" }}
          title="Delete"
          onClick={() =>
            Swal.fire({
              text: "Are you sure to Delete This Refund?",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `Delete`,
              denyButtonText: `Cancel`,
              confirmButtonColor: "#39f",
              icon: "info",
              allowOutsideClick:false,
            }).then((res) => {
              if (res.isConfirmed) {
                deleteRefundItem();
              }
            })
          }
        />
      </td>
      {/* // : <td></td>} */}
    </tr>
  );
};

export default RefundItem;
