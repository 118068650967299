import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import { ImTruck } from "react-icons/im";
import Select from "react-select";
import Swal from "sweetalert2";
import { ckcs, siteUrl } from "../../actions/common/ApiLinks";
import { updateShippingCharge } from "../../actions/Orders";

const ShippingEdit = ({
  item,
  index,
  currentOrderDetails,
  OrderId,
  setLoading,
  refreshItems,
  setRefreshItems,
  shippingMethods
}) => {
  const [editCharge, setEditCharge] = useState(false);
  const [ShippingRow, setShippingRow] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null)
  useEffect(() => {
    setShippingRow(item);
  }, [item]);
  const handleSelectMethod = (selected) => {
    setSelectedMethod(selected);
    setShippingRow({...ShippingRow, method_id: selected.value})
  }
  const handleShippingName = (e) => {
    setShippingRow({...ShippingRow, method_title: e.target.value})
  }
  const deleteShippingItem = (Id) => {
    setLoading(true);
    let adata = {
      id: Id,
      instance_id: '',
      meta_data: [],
      method_id: null,
      method_title: "",
      taxes: {},
      total: "0.00",
    }
    let data = {
      id: OrderId,
      shipping_lines: [{...adata}]
    };
  axios
    .put(`${siteUrl}orders/${OrderId}?${ckcs}`, data)
    .then((res) => {
      setLoading(false);
      Swal.fire({
        text: "Shipping Line removed Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
  }
  return (
    <tr key={index}>
      <td>
        {editCharge ? (
          <div>
            <ImTruck />
            <Select
              options={shippingMethods}
              placeholder="Select status"
              className="abc mt-2"
              name="status"
              value={selectedMethod}
              onChange={handleSelectMethod}
            />
            <input
              type="text"
              value={ShippingRow.method_title}
              onChange={handleShippingName}
            />
          </div>
        ) : (
          <p>
            <ImTruck /> {item?.method_title}
          </p>
        )}
        {item.meta_data.length !== 0 ? (
          <p>
            <b>Items:</b>{" "}
            {currentOrderDetails?.line_items?.map((item) => {
              return (
                <>
                  {item.name} * {item.quantity}
                  <br />
                </>
              );
            })}
          </p>
        ) : (
          ""
        )}
      </td>
      <td></td>
      {editCharge ? (
        <td>
          <input
            type="number"
            value={ShippingRow.total}
            style={{ width: "100%" }}
            onChange={(e) =>
              setShippingRow({ ...ShippingRow, total: e.target.value })
            }
            onBlurCapture={() => {
              let data = {
                id: OrderId,
                shipping_lines: [{ ...ShippingRow }],
              };
              setLoading(true);
              axios
                .put(`${siteUrl}orders/${OrderId}?${ckcs}`, data)
                .then((res) => {
                  setLoading(false);
                  setEditCharge(false);
                  Swal.fire({
                    text: "Updated Successfully.",
                    icon: "success",
                    allowOutsideClick:false,
                  }).then((res) => {
                    if (res.isConfirmed) {
                      setRefreshItems(!refreshItems);
                    }
                  });
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            }}
          />
        </td>
      ) : (
        <td>{item.total}</td>
      )}
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      {/* {currentOrderDetails?.status == "on-hold" ? */}
      <td>
        {editCharge ? (
          <FaTimes
          style={{ cursor: "pointer" }}
          onClick={() => {
              setShippingRow(item)
              setEditCharge(!editCharge);
            }}
          />
        ) : (
          <FaEdit
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditCharge(!editCharge);
            }}
          />
        )}
        &nbsp;&nbsp;&nbsp;
        <FaTrashAlt
          style={{ cursor: "pointer" }}
          title="Delete"
          onClick={() =>
            Swal.fire({
              title: "Do you want to Delete the Shipping?",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `Delete`,
              denyButtonText: `Cancel`,
              confirmButtonColor: "#39f",
              allowOutsideClick:false,
              icon: "info",
            }).then(res=>{
              if(res.isConfirmed){
                deleteShippingItem(item.id);
              }
            })
          }
        />
      </td>
      {/* // : <td></td>} */}
    </tr>
  );
};

export default ShippingEdit;
