import React from "react";
import SaleByProduct from "./SaleByProduct";
import SaleCategory from "./SaleCategory";
import SaleCenter from "./SaleCenter";
import Skeleton from "@mui/material/Skeleton";

const SummaryWidet = ({
  data,
  topSeller,
  saleData,
  loading,
  centerData,
  catLoading,
  centerLoading,
  sellerLoading,
}) => {
  return (
    <div>
      <div className="row" style={{ height: "100%" }}>
        <div className="col-md-6 mb-2 mt-4 transparent">
          <div className="card card-dark-blue">
            {data?.map((item, i) => (
              <div className="card-body" key={i}>
                <p className="fs-30 mb-4">Summary</p>
                <p>
                  Net Sales:{" "}
                  <span>Rs. {item?.net_sales ? item.net_sales : "0.00"}/-</span>
                </p>
                <p>
                  Total Orders:{" "}
                  <span>{item.total_orders ? item.total_orders : 0}</span>
                </p>
                <p className="d-flex">
                  Top Seller(by unit):
                  {sellerLoading ? (
                    <span>
                      <Skeleton variant="text" width={"150px"} />
                    </span>
                  ) : (
                    <span>{topSeller?.name}</span>
                  )}
                </p>
                <p className="d-flex">
                  Top Seller(by value):
                  {sellerLoading ? (
                    <span>
                      <Skeleton variant="text" width={"150px"} />
                    </span>
                  ) : (
                    <span>{topSeller?.name}</span>
                  )}
                </p>
              </div>
            ))}
          </div>
          <SaleCenter centerData={centerData} centerLoading={centerLoading} />
        </div>
        <SaleCategory
          saleData={saleData}
          loading={loading}
          catLoading={catLoading}
        />
      </div>
    </div>
  );
};

export default SummaryWidet;
