import React, { useMemo, useState } from "react";
import { TablePagination } from "@material-ui/core";
import SortIcons from "../common/SortIcons";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SaleByProduct = ({
  data,
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  products,
}) => {
  const [up, setUp] = useState(true);
  const [sorting, setSorting] = useState({
    field: "No_of_Units_Sold",
    order: "asc",
  });
  const headerData = [
    {
      name: "Products",
      field: "product_name",
      numeric: false,
      sortable: true,
    },
    {
      name: "Total Unit(s) Sold",
      field: "No_of_Units_Sold",
      numeric: true,
      sortable: true,
    },
    {
      name: "Net Sales amount",
      field: "Net_Sales_Amount",
      numeric: true,
      sortable: true,
    },
  ];
  const productsData = [
    { product: "Product", unitSold: "300", netsales: "34,500" },
    { product: "Test Product", unitSold: "100", netsales: "36,500" },
  ];
  const commentsData = useMemo(() => {
    if (products && products.length !== 0) {
      let computedComments = products;
      if (sorting.field) {
        computedComments = computedComments.sort((a, b) => {
          if (a[sorting.field] < b[sorting.field]) {
            return sorting.order === "asc" ? 1 : -1;
          } else if (a[sorting.field] > b[sorting.field]) {
            return sorting.order === "asc" ? -1 : 1;
          }
        });
      }
    }
  }, [sorting, productsData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="card card-dark-blue mt-2">
      <div className="card-body">
        <p className="fs-30 mb-4">Sales by Product</p>
        {loading ? (
          <Stack spacing={1}>
            <Skeleton variant="text" width={"95%"} />
            <Skeleton variant="pulse" width={"95%"} height={118} />
            <Skeleton variant="text" width={"95%"} />
            <Skeleton variant="pulse" width={"95%"} height={118} />
          </Stack>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {headerData.map((item, index) => (
                      <th key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.name}
                          {item.sortable ? (
                            <SortIcons
                              up={up}
                              setUp={setUp}
                              sorting={sorting}
                              setSorting={setSorting}
                              item={item}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {products && products?.length !== 0 ? (
                    products.map((item, i) => (
                      <tr key={i}>
                        <td data-label="Products">{item.product_name}</td>
                        <td data-label="units sold">
                          {item.No_of_Units_Sold ? item.No_of_Units_Sold : 0}
                        </td>
                        <td data-label="net sales">
                          Rs. {item.Net_Sales_Amount}/-
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div className="places">
                          {" "}
                          {loading ? "Loading......" : "No Data Available"}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <TablePagination
              component="div"
              count={data?.totalcount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SaleByProduct;
