import React, { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { auth } from "../../firebase";
import Loader from "../../common/loader/loader";
import { useDispatch } from "react-redux";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import projectContext from "../../context/App.Context";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Drawer } from "@material-ui/core";
import Sidebar from "./Sidebar";
import MobileSidebar from "./MobileSidebar";

function Header() {
  const [loading, setLoading] = useState(false);
  const [showMobieBar, setShowMobileBar] = useState(false);
  const { sidebarStatus, setSidebarStatus } = useContext(projectContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
  const classes = useStyles();
  const signOut = () => {
    setLoading(true);
    auth.signOut().then((res) => {
      setLoading(false);
      localStorage.clear();
      sessionStorage.clear();
      history.push("/login");
    });
  };
  const handleCollapse = () => {
    setSidebarStatus(!sidebarStatus);
  };
  return (
    <div>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        style={{ width: "200px" }}
        onClose={toggleDrawer("left", false)}
      >
        {
          <div
            className={clsx(classes.list, {
              [classes.fullList]: "left" === "top" || "left" === "bottom",
            })}
          >
            <MobileSidebar />
          </div>
        }
      </Drawer>
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        {loading ? <Loader /> : ""}
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo mr-5" href={"/"}>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/logo.jpg"}
              className="mr-2"
              alt="logo"
            />
          </a>
          <a
            className="navbar-brand brand-logo-mini"
            style={{ width: "100%" }}
            href={"/"}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/logo-mini.svg"}
              alt="logo"
            />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={handleCollapse}
          >
            {sidebarStatus ? (
              <AiOutlineMenuFold style={{ color: "green", fontSize: "25px" }} />
            ) : (
              <AiOutlineMenuUnfold
                style={{ color: "green", fontSize: "25px" }}
              />
            )}
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="icon-bell mx-0" />
                <span className="count" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <p className="mb-0 font-weight-normal float-left dropdown-header">
                  Notifications
                </p>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="ti-info-alt mx-0" />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      Application Error
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="ti-settings mx-0" />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      Settings
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Private message
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="ti-user mx-0" />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      New user registration
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      2 days ago
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                id="profileDropdown"
              ></a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <a className="dropdown-item">
                  <i className="ti-settings text-primary" />
                  Settings
                </a>
                <a className="dropdown-item">
                  <i className="ti-power-off text-primary" />
                  Logout
                </a>
              </div>
            </li>
            <li className="nav-item nav-settings d-none d-lg-flex">
              <div className="logout-item" onClick={signOut}>
                <i className="ti-power-off text-primary" />
                Logout
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu" onClick={toggleDrawer("left", true)} />
          </button>
        </div>
      </nav>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
