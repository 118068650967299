import React, { useState, useEffect, useContext } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { FaEdit, FaTimes, FaQuestionCircle, FaTrashAlt } from "react-icons/fa";
import { ImTruck } from "react-icons/im";
import { Prompt, useHistory, useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import {
  createAFullRefund,
  createARefund,
  getAllCountriesData,
  getAllShippingMethodsData,
  getAllStatesData,
  getDeliveryDate,
  getOrderDetailsById,
  updateDeliveryDate,
  updateShippingAddress,
  updateShippingCharge,
  updateStaffAndFCenter,
} from "../actions/Orders";
import Loader from "../common/loader/loader";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import Divider from "../assets/Sort.svg";
import SortIcons from "./common/SortIcons";
import Swal from "sweetalert2";
import EditOrderRow from "./EditOrderRow";
import AddNewProductToOrder from "./common/AddNewProductToOrder";
import axios from "axios";
import { apiKey, ckcs, siteUrl } from "../actions/common/ApiLinks";
import Select from "react-select";
import DatePicker from "react-date-picker";
import ShippingEdit from "./common/ShippingEdit";
import { moviDb } from "../firebase";
import RefundItem from './RefundItem';
import projectContext from "../context/App.Context";

const EditOrder = () => {
  const { OrderId } = useParams();
  const history = useHistory();
  const {
    sidebarStatus
  } = useContext(projectContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrderDetails, setCurrentOrderDetails] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [toggleGeneral, setToggleGeneral] = useState(false);
  const [toggleBilling, setToggleBilling] = useState(false);
  const [toggleShipping, setToggleShipping] = useState(false);
  const statusData = localStorage.getItem('status');
  const [sorting, setSorting] = useState({
    field: "id",
    order: "asc",
  });
  const [up, setUp] = useState(true);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [displayEditIcon, setDisplayEditIcon] = useState(true);
  const [isAddNewProduct, setIsAddNewProduct] = useState(false);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [productsRelOrder, setProductRelOrder] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [refreshItems, setRefreshItems] = useState(false);
  let newDate = currentOrderDetails?.meta_data[3]?.value
    ? new Date(currentOrderDetails?.meta_data[3]?.value)
    : new Date();
  const [value, onChange] = useState(newDate);
  const [countries, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [fulfillCenters, setFulfillCenters] = useState([]);
  const [selectedFFcenter, setSelectedFFcenter] = useState(null);
  const [shippingaddress, setShippingAddress] = useState(
    currentOrderDetails?.shipping
  );
  const [selectedCountry, setSelectedCountry] = useState({
    label: "india",
    value: "IN",
  });
  const [selectedState, setSelectedState] = useState({
    label: "kerala",
    value: "KL",
  });
  const [shippingMethods, setShippingMethods] = useState(null);
  const [editShippingAddress, setEditShippingAddress] = useState(null);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState({});
  const [isRefund, setIsrefund] = useState(false);
  const [refundAmount, setRefundAmount] = useState(null);
  const [netPay, setNetPay] = useState(null);
  const [refundItems, setRefundItems] = useState([]);
  const [refundTotalAmount, setRefundTotalAmount] = useState('');
  const [disableRefundBtn, setRefundBtnDisable] = useState(true);
  const [taxAmountToRefund, setTaxAmountToRefund] = useState(0);
  const [fullRefundItems, setFullRefundItems] = useState([]);
  const [costAmountToRefund, setCostAmountToRefund] = useState(0);
  const [sendPrompt, setSendPrompt] = useState(false);
  useEffect(() => {
    getOrderDetailsById(
      setIsLoading,
      OrderId,
      setCurrentOrderDetails,
      setOrderInfo,
      setProductRelOrder,
      setOriginalProducts
    );
  }, [refreshItems]);
  useEffect(()=>{
    const totalRefund = currentOrderDetails?.refunds?.reduce(
      (accum, item) => accum + Number(item.total),
      0
    );
  setRefundAmount(totalRefund?.toFixed(2))
  let npay = Number(currentOrderDetails?.total) + totalRefund;
  setNetPay(npay)
  setRefundItems(
    currentOrderDetails?.line_items?.map((item) => ({
      id: item.id,
      refund_total: 0,
      quantity: 0,
      refund_tax: item?.taxes.length != 0 && item?.taxes?.map(item =>({
        id: item.id, refund_total: item.total
      })),
    }))
  );
  setFullRefundItems(
    currentOrderDetails?.line_items?.map((item) => ({
      id: item.id,
      refund_total: item.subtotal,
      quantity: 0,
      refund_tax: item?.taxes.length != 0 && item?.taxes?.map(item =>({
        id: item.id, refund_total: item.total
      })),
    }))
  );
  },[currentOrderDetails]);
  useEffect(() => {
    const totalRefund = refundItems?.reduce(
      (accum, item) => accum + Number(item.refund_total),
      0
    );
    let taxRefund = (fullRefundItems?.map(item => item.refund_tax?.reduce(
      (accum, item) => accum + Number(item.refund_total),
      0
    )));
    setCostAmountToRefund(fullRefundItems?.reduce(
      (accum, item) => accum + Number(item.refund_total),
      0
    ))
    if(taxRefund?.length !== 0){
      setTaxAmountToRefund(taxRefund?.reduce((accum, item) => accum + Number(item)))
    }
    setRefundTotalAmount(totalRefund?.toString())
    if(Number(totalRefund) !== 0){
      setRefundBtnDisable(false)
    }else{
      setRefundBtnDisable(true)
    }
  },[refundItems])
  useEffect(() => {
    setShippingAddress(currentOrderDetails?.shipping);
    setEditShippingAddress(currentOrderDetails?.shipping);
    let staffN = currentOrderDetails?.meta_data?.find(
      (item) => item.key == "_wc_acof_3"
    );
    staff.map((item) => {
      if (item?.value == `${staffN?.value}`) {
        setSelectedStaff(item);
      }
    });
    let cen = currentOrderDetails?.meta_data?.find(
      (item) => item.key == "_wc_acof_2"
    );
    fulfillCenters.map((item) => {
      if (
        item.value.toLowerCase() ==
        `${cen?.value}`
      ) {
        setSelectedFFcenter(item);
      }
    });
  }, [currentOrderDetails]);
  const headerData = [
    {
      name: "Item",
      field: "id",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Goods cost",
      field: "date_created",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Cost",
      field: "status",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Qty",
      field: "total",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Total",
      field: "status",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "CGST",
      field: "actions",
      numeric: true,
      sortable: false,
    },
    {
      name: "SGST",
      field: "total",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "CESS",
      field: "status",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Actions",
      field: "payment_status",
      numeric: true,
      sortable: false,
    },
  ];
  var subTotal = currentOrderDetails?.line_items?.reduce(
    (accum, item) => accum + Number(item.subtotal),
    0
  );

  const handleDeleteOrder = () => {
    setIsLoading(true);
    axios
      .delete(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, {
        force: true,
      })
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          text: "Order Deleted Successfully",
          icon: "success",
          allowOutsideClick:false,
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/orders");
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const updateDeliveryDateTime = () => {
    var x = parseInt(+new Date(value) / 1000) + 86400;
    updateDeliveryDate(OrderId, x, refreshItems, setRefreshItems, setIsLoading);
  };
  useEffect(() => {
    getAllCountriesData(setIsLoading, setCountries);
    getAllStatesData(setIsLoading, setStates, selectedCountry);
    getAllShippingMethodsData(setIsLoading, setShippingMethods);
  }, []);

  const handleSelectCountry = (selected) => {
    setSelectedCountry(selected);
    getAllStatesData(setIsLoading, setStates, selected);
    setShippingAddress({
      ...shippingaddress,
      country: selected.value,
    });
  };
  const handleSelectState = (selected) => {
    setSelectedState(selected);
    setShippingAddress({
      ...shippingaddress,
      state: selected.value,
    });
  };
  const handleUpdateAddress = () => {
    const data = {
      shipping: shippingaddress,
    };
    updateShippingAddress(
      OrderId,
      data,
      refreshItems,
      setRefreshItems,
      setIsLoading,
      setToggleShipping
    );
  };
  const saveOrderWithUpdatedDetails = () => {
    let a = productsRelOrder?.map((opt) => ({
      id: opt.id,
      quantity: opt.quantity,
    }));
    setIsLoading(true);
    let data = {
      id: OrderId,
      line_items: a,
    };
    axios
      .put(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, data)
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          text: "Product(s) updated successfully in the order",
          icon: "success",
          allowOutsideClick:false,
        }).then((res) => {
          if (res.isConfirmed) {
            setIsAddNewProduct(false);
            setRefreshItems(!refreshItems);
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const AddShippingFee = () => {
    let ab = [
      {
        method_title: "",
        method_id: "shipping",
        total: "0.00",
      },
    ];
    let data = {
      id: OrderId,
      shipping_lines: ab,
    };
    updateShippingCharge(
      OrderId,
      data,
      refreshItems,
      setRefreshItems,
      setIsLoading,
      setToggleShipping
    );
  };
  useEffect(() => {
    getStaff();
    getFFcenters();
    getDeliveryDate(OrderId, setIsLoading, setOrderDeliveryDate);
  }, []);
  useEffect(() => {
    if (Object.keys(orderDeliveryDate).length !== 0) {
      let date = orderDeliveryDate?.order["Delivery Date"];
      let newDateStamp = date ? new Date(date) : new Date();
      onChange(newDateStamp);
    }
  }, [orderDeliveryDate]);
  const getStaff = () => {
    setIsLoading(true);
    moviDb
      .collection("users")
      .get()
      .then((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) =>
          postData.push({ value: doc.id, label: doc.data().assignee_name })
        );
        setStaff(postData);
        setIsLoading(false);
      });
  };
  const getFFcenters = () => {
    setIsLoading(true);
    moviDb
      .collection("fulfillment_centers")
      .get()
      .then((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) =>
          postData.push({
            label: doc.data().fc_name,
            value: doc.data().fc_name,
          })
        );
        setFulfillCenters(postData);
        setIsLoading(false);
      });
  };
  const handleUpdateStaffandCenter = () => {
    const data = {
      meta_data: [
        {
          key: "_wc_acof_2",
          value: selectedFFcenter?.value.toLowerCase(),
        },
        {
          key: "_wc_acof_3",
          value: selectedStaff?.value.toLowerCase(),
        },
      ],
    };
    updateStaffAndFCenter(OrderId, setIsLoading, data);
  };
  const processRefund = () => {
    let data = {
      order_id: OrderId,
      amount: refundTotalAmount,
      api_refund: false,
      line_items: refundItems,
    };
    createARefund(OrderId, setIsLoading, data, refreshItems, setRefreshItems, setIsrefund)
  }
  const processFullRefund = () => {
    let data = {
      order_id: OrderId,
      amount: ((taxAmountToRefund+costAmountToRefund)-(-refundAmount)).toString(),
      api_refund: false,
      line_items: fullRefundItems,
    };
    createAFullRefund(OrderId, setIsLoading, data, refreshItems, setRefreshItems, setIsrefund)
  }
  const handleUpdateStatus = () => {
    const data = {
      status: `${statusData}`,
    };
    setIsLoading(true);
    axios
      .put(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, data)
      .then((res) => {
        setIsLoading(false);
        history.push(`/orders`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }
  const backToOrders = () => {
    Swal.fire({
      title:
        "Do you want to update previous status?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Update`,
      denyButtonText: `Cancel`,
      confirmButtonColor: "#39f",
      icon: "info",
    }).then((res) => {
      if (res.isConfirmed) {
        handleUpdateStatus();
      }else{
        history.push(`/orders`);
      }
    });
  }
  useEffect(()=>{
    if(statusData == 'on-hold'){
      setSendPrompt(false)
    }else{
      setSendPrompt(true)
    }
  },[statusData])
  return (
    <div className="container-scroller">
      <Prompt
        when={sendPrompt}
        message={(location, action) => {
          if (action === "POP") {
            console.log("Backing up...");
          }
          return location.pathname.startsWith("/app")
            ? true
            : `you haven't updated status, Are you sure you want to go to ${location.pathname}?`;
        }}
      />
      <ToastContainer />
      {isAddNewProduct ? (
        <AddNewProductToOrder
          setRefreshItems={setRefreshItems}
          refreshItems={refreshItems}
          setIsLoading={setIsLoading}
          setIsAddNewProduct={setIsAddNewProduct}
          order={currentOrderDetails}
          OrderId={OrderId}
        />
      ) : (
        ""
      )}
      {isLoading ? <Loader /> : ""}
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title1">
                      <div className="viewOrderHeader">
                        <h4>Order #{OrderId} Details</h4>
                        <div className="d-flex">
                          <ButtonGroup size="sm">
                            <Button onClick={backToOrders}>
                              Back to Orders
                            </Button>
                          </ButtonGroup>
                          <Button
                            variant="danger"
                            size="sm"
                            className="ml-2"
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure to delete this order?",
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: `Yes`,
                                denyButtonText: `Cancel`,
                                confirmButtonColor: "#39f",
                                icon: "info",
                                allowOutsideClick: false,
                              }).then((response) => {
                                if (response.isConfirmed) {
                                  handleDeleteOrder();
                                }
                              })
                            }
                          >
                            Move to Trash
                          </Button>
                        </div>
                      </div>
                      {orderInfo?.payment_method_title ? (
                        <p>Payment Via {orderInfo?.payment_method_title}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Row>
                      <h4 className="pl-2">Products on this order</h4>
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {headerData.map((item, index) => (
                                <th key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.name}
                                    {item.sortable ? (
                                      <SortIcons
                                        up={up}
                                        setUp={setUp}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        item={item}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {currentOrderDetails?.line_items?.length !== 0 &&
                              currentOrderDetails?.line_items.map(
                                (item, index) => {
                                  return (
                                    <EditOrderRow
                                      key={index}
                                      item={item}
                                      index={index}
                                      setIsEditOrder={setIsEditOrder}
                                      displayEditIcon={displayEditIcon}
                                      setDisplayEditIcon={setDisplayEditIcon}
                                      cancelEdit={cancelEdit}
                                      setCancelEdit={setCancelEdit}
                                      productsRelOrder={productsRelOrder}
                                      setProductRelOrder={setProductRelOrder}
                                      refreshItems={refreshItems}
                                      setRefreshItems={setRefreshItems}
                                      OrderId={OrderId}
                                      originalProducts={originalProducts}
                                      currentOrderDetails={currentOrderDetails}
                                      setCurrentOrderDetails={
                                        setCurrentOrderDetails
                                      }
                                      refundItems={refundItems}
                                      setRefundItems={setRefundItems}
                                      setIsLoading={setIsLoading}
                                      isRefund={isRefund}
                                    />
                                  );
                                }
                              )}{" "}
                            {currentOrderDetails?.shipping_lines.length != 0
                              ? currentOrderDetails?.shipping_lines.map(
                                  (item, index) => {
                                    return (
                                      <ShippingEdit
                                        key={index}
                                        index={index}
                                        item={item}
                                        currentOrderDetails={
                                          currentOrderDetails
                                        }
                                        OrderId={OrderId}
                                        setLoading={setIsLoading}
                                        refreshItems={refreshItems}
                                        shippingMethods={shippingMethods}
                                        setRefreshItems={setRefreshItems}
                                      />
                                    );
                                  }
                                )
                              : ""}
                            {currentOrderDetails?.refunds?.length !== 0
                              ? currentOrderDetails?.refunds.map(
                                  (item, index) => {
                                    return (
                                      <RefundItem
                                        item={item}
                                        key={index}
                                        OrderId={OrderId}
                                        setLoading={setIsLoading}
                                        refreshItems={refreshItems}
                                        setRefreshItems={setRefreshItems}
                                        refundItems={refundItems}
                                      />
                                    );
                                  }
                                )
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                    <div className="float-right mr-2">
                      <div className="row priceTotal">
                        <p>Items Subtotal: </p>
                        <p className="fw-500">
                          <b>Rs {subTotal}</b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>Shipping: </p>
                        <p className="fw-500">
                          <b>Rs {currentOrderDetails?.shipping_total}</b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>CGST: </p>
                        <p className="fw-500">
                          <b>
                            Rs{" "}
                            {currentOrderDetails?.line_items[0]?.taxes[0]
                              ?.subtotal &&
                              parseFloat(
                                currentOrderDetails?.line_items[0]?.taxes[0]
                                  ?.subtotal
                              ).toFixed(2)}
                          </b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>SGST: </p>
                        <p className="fw-500">
                          <b>
                            Rs{" "}
                            {currentOrderDetails?.line_items[0]?.taxes[1]
                              ?.subtotal &&
                              parseFloat(
                                currentOrderDetails?.line_items[0]?.taxes[1]
                                  ?.subtotal
                              ).toFixed(2)}
                          </b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>CESS: </p>
                        <p className="fw-500">
                          <b>
                            Rs{" "}
                            {currentOrderDetails?.line_items[0]?.taxes[2]
                              ?.subtotal &&
                              parseFloat(
                                currentOrderDetails?.line_items[0]?.taxes[2]
                                  ?.subtotal
                              ).toFixed(2)}
                          </b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>Order Total: </p>
                        <p className="fw-500">
                          <b>Rs {currentOrderDetails?.total}</b>
                        </p>
                      </div>
                      <div className="row priceTotal">
                        <p>Cost of Goods: </p>
                        <p className="fw-500">
                          <b>Rs 0.00</b>
                        </p>
                      </div>
                      {currentOrderDetails?.refunds?.length !== 0 ? (
                        <div
                          style={{ borderTop: "2px solid grey", width: "100%" }}
                        >
                          <div className="row priceTotal">
                            <p>Refunded: </p>
                            <p className="fw-500">
                              <b>Rs {refundAmount}</b>
                            </p>
                          </div>
                          <div className="row priceTotal">
                            <p>Net Payment: </p>
                            <p className="fw-500">
                              <b>Rs {netPay}</b>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr />
                  {currentOrderDetails?.status == "on-hold" ? (
                    <>
                      {!isRefund ? (
                        <Row>
                          <div
                            className="row d-flex align-items-center changeState"
                            style={{
                              paddingLeft: "5%",
                              paddingRight: "5%",
                              paddingBottom: "1%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mr-1"
                              onClick={() => setIsAddNewProduct(true)}
                            >
                              Add Product(s)
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="mr-1"
                              size="sm"
                              onClick={AddShippingFee}
                            >
                              Add Shipping
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="mr-1"
                              size="sm"
                              onClick={() => {
                                setIsEditOrder(false);
                                setDisplayEditIcon(true);
                                setCancelEdit(true);
                                window.location.reload();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              onClick={saveOrderWithUpdatedDetails}
                            >
                              Save
                            </Button>
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className="row d-flex align-items-center"
                    style={{
                      justifyContent: "space-between",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      paddingBottom: "1%",
                    }}
                  >
                    {isRefund ? (
                      <div>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => setIsrefund(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="sm"
                          className="ml-3"
                          onClick={processRefund}
                          disabled={disableRefundBtn}
                        >
                          Refund Manually
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div>
                          <Button size="sm" onClick={() => setIsrefund(true)}>
                            Refund
                          </Button>
                          <Button
                            size="sm"
                            className="ml-2"
                            onClick={() =>
                              Swal.fire({
                                text: "Are you sure, you wish to process this refund? This action cannot be undone.",
                                showDenyButton: true,
                                showCancelButton: false,
                                confirmButtonText: `Initiate Refund`,
                                denyButtonText: `Cancel`,
                                confirmButtonColor: "#39f",
                                allowOutsideClick: false,
                                icon: "info",
                              }).then((response) => {
                                if (response.isConfirmed) {
                                  processFullRefund();
                                }
                              })
                            }
                          >
                            Full Refund
                          </Button>
                        </div>
                        <div>
                          <FaQuestionCircle />
                          This order is no longer editable.
                        </div>
                      </>
                    )}
                  </div>
                  <div className="pl-4 pb-4 pr-4">
                    <div
                      className="p-2"
                      style={{ border: "1px solid grey", borderRadius: "5px" }}
                    >
                      <div
                        className="d-flex align-items-center pr-5"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4>Edit Order Delivery Date and/or Time</h4>
                        <Button size="sm" onClick={updateDeliveryDateTime}>
                          Update
                        </Button>
                      </div>
                      <div className="d-flex align-items-center">
                        <h6 className="col-sm-2">Delivery Date: </h6>
                        <DatePicker
                          onChange={onChange}
                          value={value}
                          format="yyyy-MM-dd"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pl-4 pb-4 pr-4">
                    <div
                      className="p-2"
                      style={{
                        border: "1px solid grey",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center pr-5"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4>Change Fulfillment Center and/or Delivery Staff</h4>
                        <Button size="sm" onClick={handleUpdateStaffandCenter}>
                          Update
                        </Button>
                      </div>
                      <div className="d-flex">
                        <div
                          className="d-flex align-items-center col-sm-4"
                          style={{ flexDirection: "column" }}
                        >
                          <h6>Fulfillment Center: </h6>
                          <Select
                            options={fulfillCenters}
                            placeholder="Select Center"
                            className="abc"
                            name="status"
                            value={selectedFFcenter}
                            onChange={(e) => {
                              setSelectedFFcenter(e);
                            }}
                          />
                        </div>
                        <div
                          className="d-flex align-items-center col-sm-4"
                          style={{ flexDirection: "column" }}
                        >
                          <h6>Delivery Staff: </h6>
                          <Select
                            options={staff}
                            placeholder="Select Staff"
                            className="abc"
                            name="status"
                            value={selectedStaff}
                            onChange={(e) => {
                              setSelectedStaff(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-4 pb-4 pr-4">
                    <div
                      className="p-2"
                      style={{
                        border: "1px solid grey",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center pr-5"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4>View or Edit Shipping Address</h4>
                        {toggleShipping ? (
                          <div>
                            <Button size="sm" onClick={handleUpdateAddress}>
                              Update
                            </Button>
                            <Button
                              size="sm"
                              className="ml-3"
                              variant="outline-secondary"
                              onClick={() => {
                                setShippingAddress(editShippingAddress);
                                setToggleShipping(!toggleShipping);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <Button
                            size="sm"
                            onClick={() => setToggleShipping(!toggleShipping)}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                      <div>
                        <div className="row pt-2">
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">First Name: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.first_name}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    first_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">
                                {shippingaddress?.first_name}
                              </p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">Last Name: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.last_name}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    last_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">
                                {shippingaddress?.last_name}
                              </p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">Address1: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.address_1}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    address_1: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">
                                {shippingaddress?.address_1}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">Address2: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.address_2}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    address_2: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">
                                {shippingaddress?.address_2}
                              </p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">Company: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.company}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    company: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">{shippingaddress?.company}</p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">City: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.city}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    city: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">{shippingaddress?.city}</p>
                            )}
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="col-sm-4">Country: </h6>
                            {toggleShipping ? (
                              <Select
                                options={countries}
                                placeholder="Select status"
                                className="abc"
                                name="status"
                                onChange={handleSelectCountry}
                                value={selectedCountry}
                              />
                            ) : (
                              <p className="ml-3">{shippingaddress?.country}</p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">State: </h6>
                            {toggleShipping ? (
                              <Select
                                options={states}
                                placeholder="Select status"
                                className="abc"
                                name="status"
                                onChange={handleSelectState}
                                value={selectedState}
                              />
                            ) : (
                              <p className="ml-3">{shippingaddress?.state}</p>
                            )}
                          </div>
                          <div className="d-flex align-items-center col-sm-4">
                            <h6 className="">Pin Code: </h6>
                            {toggleShipping ? (
                              <input
                                type="text"
                                value={shippingaddress?.postcode}
                                onChange={(e) =>
                                  setShippingAddress({
                                    ...shippingaddress,
                                    postcode: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <p className="ml-3">
                                {shippingaddress?.postcode}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a
                  href="https://myonlinevipani.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank" rel="noreferrer">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
