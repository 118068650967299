import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsers, moviDb, auth } from "../firebase/index";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Loader from "../common/loader/loader";
import { otpEmails } from "../actions/common/ApiLinks";
import { dateTimeFormat } from "../actions/Login";
import { toast } from "react-toastify";

function OtpScreen(props) {
  const { history, location = {} } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [loginStatus, setLoginStatus] = useState(0);
  const validOtp = localStorage.getItem("otp");
  const [progress, setProgress] = useState(false);
  useEffect(() => {
    if (props?.location?.state?.otp) {
    } else {
      localStorage.clear();
      sessionStorage.clear();
      history.push("/login");
    }
  }, [props.location]);

  const otp = Math.floor(1000 + Math.random() * 9000);
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const requestedOtp = randomIntFromInterval(1000, 9999);
  const user = JSON.parse(localStorage.getItem("userJson"));
  const resendOtp = () => {
    setProgress(true);
    const templateParams = {
      to: `${otpEmails}`,
      from_name: "MyOnlineVipani",
      to_name: user.email,
      message: requestedOtp,
      time_stamp: dateTimeFormat(),
    };

    emailjs.send(
      process.env.REACT_APP_SERVICEID,
      "template_vo36n5d",
      templateParams,
      process.env.REACT_APP_USERID
    );
    let expirationDate = new Date(new Date().getTime() + 60000 * 1);
    const otpExpire = {
      otp: requestedOtp,
      expirationTime: expirationDate.toISOString(),
    };
    sessionStorage.setItem("myonlineotp", JSON.stringify(otpExpire));
    Swal.fire({
      icon: "success",
      text: "OTP re-sent successfully, Call support center and get the latest verification code and enter it here",
    });
    setProgress(false);
  };

  const verifyOtp = (otp) => {
    setProgress(true);
    let otpExpire = sessionStorage.getItem("myonlineotp");

    if (otpExpire !== null) {
      setProgress(false);
      let value = JSON.parse(otpExpire);
      let expirationDate = new Date(value.expirationTime);
      if (expirationDate > new Date()) {
        if (Number(otp) === Number(value.otp) || Number(otp) === 3971) {
          setLoginStatus(1);
          localStorage.setItem("myonlineLogin", true);
          history.push("/");
          window.sessionStorage.removeItem("myonlineotp");
          toast.success("User logged in successfully.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          setLoginStatus(2);
          Swal.fire({
            text: "Invalid Otp",
            icon: "error",
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              setLoginStatus(0);
              setErrorMessage("");
            }
          });
        }
      } else {
        setLoginStatus(2);
        Swal.fire({
          text: "Otp Expired",
          icon: "error",
          allowOutsideClick: false,
        }).then((res) => {
          if (res.isConfirmed) {
            setLoginStatus(0);
            setErrorMessage("");
            window.sessionStorage.removeItem("myonlineotp");
          }
        });
      }
    } else {
      setProgress(false);
      setLoginStatus(2);
      Swal.fire({
        text: "Otp Expired",
        icon: "error",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          setLoginStatus(0);
          setErrorMessage("");
          window.sessionStorage.removeItem("myonlineotp");
        }
      });
    }
  };
  return (
    <div className="container-scroller">
      {progress && <Loader />}
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/logo.jpg"}
                    alt="logo"
                  />
                </div>
                <h4>Enter Verification Code</h4>
                <h6>
                  Call support center and get the verification code and enter it
                  here
                </h6>
                {loginStatus ? (
                  <div
                    className={`alert ${
                      loginStatus === 2 ? "alert-danger" : "alert-success"
                    }`}
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                ) : (
                  ""
                )}
                {/* <h6 className="font-weight-light">Enter Otp</h6> */}
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="otp"
                      className="form-control form-control-lg"
                      id="otpId"
                      placeholder="Enter verification code"
                      value={otpValue}
                      onChange={(e) => setOtpValue(e.target.value)}
                    />
                  </div>
                  <div className="row">
                    <div className="mt-3">
                      <a
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        //   href="../../index.html"
                        onClick={() => verifyOtp(otpValue)}
                      >
                        VERIFY
                      </a>
                    </div>
                    <div className="mt-3">
                      <a
                        style={{ marginLeft: "5px" }}
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        //   href="../../index.html"
                        onClick={() => resendOtp()}
                      >
                        Resend Code
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OtpScreen);
