export var siteUrl = `${process.env.REACT_APP_DEVURL}/`;
export const wcUrl = `${process.env.REACT_APP_DEVWCURL}`;
var config = {
  product_list: siteUrl + `products?${process.env.REACT_APP_CKCS}`,
};
export default config;
export const devUrl = process.env.REACT_APP_DEVURL;
export const apiKey = process.env.REACT_APP_APIKEY;
export const ckcs = process.env.REACT_APP_CKCS;
export const otpEmails =
  "support@myonlinevipani.com";
