import React, { useEffect, useState } from "react";
import SummaryWidet from "./widgets/SummaryWidet";
import Select from "react-select";
import moment from "moment";
import $ from "jquery";
import {
  getSalesByCategory,
  getSalesByCenter,
  getSummaryReports,
  getTopSellerReports,
} from "../actions/Reports";
import Loader from "../common/loader/loader";

const Reports = () => {
  let date1 = new Date();

  let format1 = "YYYY-MM-DD";
  let enddate = moment().format(format1);
  let startdate = moment(date1.setDate(date1.getDate() - 7)).format(format1);
  const [isCalendarDisabled, setIsCalendarDisabled] = useState(true);
  const [startDateValue, setStartDateValue] = useState(startdate);
  const [endDateValue, setEndDateValue] = useState(enddate);
  const [loading, setLoading] = useState(false);
  const [SummaryData, setSummaryData] = useState([]);
  const [topSeller, setTopSeller] = useState([]);
  const [salesBycat, setSalesByCat] = useState([]);
  const [salesBycenter, setSalesByCenter] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [centerLoading, setCenterLoading] = useState(false);
  const [sellerLoading, setSellerLoading] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#a7a8a9",
      // minHeight: "34px",
      // height: "34px",
      minWidth: "200px",
      marginRight: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
    menu: (styles) => ({
      ...styles,
      width: "194px",
    }),
  };
  const lastModifiedOptions = [
    {
      label: "This Month",
      value: "thismonth",
    },
    {
      label: "Last Month",
      value: "lastmonth",
    },
    {
      label: "Last 7 days",
      value: "1week",
    },
    {
      label: "Custom",
      value: "custom",
    },
  ];
  const [selectedLeadDate, setSelectedLeadDate] = useState({
    label: "Last 7 days",
    value: "1week",
  });
  const startDateChangeHandler = (e) => {
    setStartDateValue(e.target.value);
    $("#startDateTxt").css({ border: "" });
  };
  const endDateChangeHandler = (e) => {
    if (startDateValue == "") {
      $("#startDateTxt").css({ border: "1px solid red" });
    } else {
      $("#startDateTxt").css({ border: "" });
    }
    setEndDateValue(e.target.value);
    enddate = e.target.value;
  };
  const lastModifiedDateChangeHandler = (event) => {
    var date = new Date();
    const format2 = "YYYY-MM-DD";
    $("#startDateTxt").css({ border: "" });
    setEndDateValue(enddate);
    setSelectedLeadDate(event);
    if (event.value == "custom") {
      setIsCalendarDisabled(false);
      setStartDateValue("");
      // setEndDateValue();
    } else if (event.value == "1week") {
      setIsCalendarDisabled(true);
      setStartDateValue(date);
      setStartDateValue(
        moment(date.setDate(date.getDate() - 7)).format(format2)
      );
    } else if (event.value == "thismonth") {
      setIsCalendarDisabled(true);
      setStartDateValue(moment().startOf("month").format(format2));
    } else if (event.value == "lastmonth") {
      setIsCalendarDisabled(true);
      setStartDateValue(
        moment().subtract(1, "months").startOf("month").format(format2)
      );
      setEndDateValue(
        moment(moment().format(format2))
          .subtract(1, "months")
          .endOf("month")
          .format(format2)
      );
    }
  };
  useEffect(() => {
    getSummaryReports(startDateValue, endDateValue, setLoading, setSummaryData);
    getTopSellerReports(
      startDateValue,
      endDateValue,
      setSellerLoading,
      setTopSeller
    );
    getSalesByCategory(
      startDateValue,
      endDateValue,
      setCatLoading,
      setSalesByCat
    );
    getSalesByCenter(
      startDateValue,
      endDateValue,
      setCenterLoading,
      setSalesByCenter
    );
  }, []);
  const filterRecords = () => {
    getSummaryReports(startDateValue, endDateValue, setLoading, setSummaryData);
    getTopSellerReports(
      startDateValue,
      endDateValue,
      setSellerLoading,
      setTopSeller
    );
    getSalesByCategory(
      startDateValue,
      endDateValue,
      setCatLoading,
      setSalesByCat
    );
    getSalesByCenter(
      startDateValue,
      endDateValue,
      setCenterLoading,
      setSalesByCenter
    );
  };
  return (
    <div className="mt-3">
      {loading ? <Loader /> : ""}
      <h3>Sales Summary:</h3>
      <div className="mt-3">
        <h4>Date Range</h4>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ alignItems: "center" }}
      >
        <div>
          <label>Select Date:</label>
          <Select
            id="leadDateDD"
            styles={customStyles}
            options={lastModifiedOptions}
            value={selectedLeadDate}
            onChange={lastModifiedDateChangeHandler}
          />
        </div>
        <div>
          <label>Start Date: MM/DD/YYYY</label>
          <input
            id="startDateTxt"
            type="date"
            max={enddate}
            value={startDateValue}
            onChange={(e) => startDateChangeHandler(e)}
            disabled={isCalendarDisabled}
            placeholder="Start Date"
            className="form-control"
            style={{ width: "200px" }}
          />
        </div>{" "}
        &nbsp;&nbsp; to &nbsp;&nbsp;
        <div>
          <label>End Date: MM/DD/YYYY</label>
          <input
            type="date"
            id="endDateTxt"
            value={endDateValue}
            onChange={(e) => endDateChangeHandler(e)}
            disabled={isCalendarDisabled}
            placeholder="End Date"
            className="form-control"
            style={{ width: "200px" }}
          />
        </div>
        <div className="places mt-3">
          <button
            class="btn btn-primary ml-2"
            type="submit"
            onClick={filterRecords}
          >
            Go
          </button>
        </div>
      </div>
      <SummaryWidet
        data={SummaryData}
        saleData={salesBycat}
        topSeller={topSeller.length != 0 ? topSeller[0] : null}
        loading={loading}
        centerData={salesBycenter}
        catLoading={catLoading}
        centerLoading={centerLoading}
        sellerLoading={sellerLoading}
      />
    </div>
  );
};

export default Reports;
