import React, { useEffect, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { handleSearchProducts } from "../../actions/Orders";
import { Button, Col, Row } from "react-bootstrap";
const AsyncInputRow = ({setNewProducts, newProducts}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [newProductObj, setNewProductObj] = useState({});
  const [selectedP, setSelectedP] = useState(null);
  const filterBy = () => true;

  const addProducttotable = () => {
    let a = [...newProducts];
      a.push(newProductObj);
      setNewProducts(a);
      setNewProductObj({})
      setQuantity(null)
  }
  return (
    <tr>
      <td xs={5}>
        <AsyncTypeahead
          filterBy={filterBy}
          id="asyncQuickSearch"
          labelKey="login"
          minLength={3}
          options={options}
          isLoading={isLoading}
          onSearch={(query) =>
            handleSearchProducts(query, setIsLoading, setOptions)
          }
          onChange={(selected) => {
            setSelectedP(selected);
            setNewProductObj({
              ...newProductObj,
              product_id: selected[0].id,
              name: selected[0].login,
            });
          }}
          placeholder="Search for product.."
          renderMenuItemChildren={(option, props) => (
            <span>{option.login}</span>
          )}
        />
      </td>
      <td xs={5}>
        <input
          type="number"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
            setNewProductObj({ ...newProductObj, quantity: Number(e.target.value) });
          }}
        />
      </td>
      <td>
        <Button size="sm" onClick={addProducttotable}>Add</Button>
      </td>
    </tr>
  );
};

export default AsyncInputRow;
