import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getUsers, moviDb, auth } from "../firebase/index";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import * as actions from "../actions/Products";
import { FaEdit, FaEye, FaPlus, FaTrashAlt } from "react-icons/fa";
import CreateProduct from "./CreateProduct";
import config, { apiKey, ckcs } from "../actions/common/ApiLinks";
import { wcUrl } from "../actions/common/ApiLinks";
import Loader from "../common/loader/loader";
import { TablePagination } from "@material-ui/core";
import Divider from "../assets/Sort.svg";
import SortIcons from "./common/SortIcons";
import Swal from "sweetalert2";
import projectContext from "../context/App.Context";
import axios from "axios";
import { Skeleton } from "@mui/material";

function Products(props) {
  const { sidebarStatus } = useContext(projectContext);
  const [createProduct, setCreateProduct] = useState(false);
  const [refreshItems, setRefreshItems] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [editProduct, setEditProduct] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sorting, setSorting] = useState({
    field: "date",
    order: "desc",
  });
  const [up, setUp] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const filterItems = ["id", "name"];
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const headerData = [
    {
      name: "Product Id",
      field: "id",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Name",
      field: "title",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Price(Inr)",
      field: "price",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Status",
      field: "status",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Actions",
      field: "actions",
      numeric: true,
      sortable: false,
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (!searchValue && searchValue < 3) {
      receiveProducts();
    }
  }, [page, rowsPerPage, refreshItems, sorting, searchValue, createProduct]);
  const receiveProducts = () => {
    setProducts([]);
    setLoading(true);
    axios
      .get(
        `${config.product_list}&orderby=${sorting.field}&order=${
          sorting.order
        }&per_page=${rowsPerPage}&page=${page + 1}&search=${searchValue}`
      )
      .then((res) => {
        setLoading(false);
        setProducts(res.data);
        setFilteredProducts(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchValue && searchValue.length >= 3) {
      searchProducts();
    }
  }, [searchValue]);
  const searchProducts = () => {
    setProducts([]);
    setLoading(true);
    axios
      .get(
        `${config.product_list}&orderby=${sorting.field}&per_page=100&order=${sorting.order}&search=${searchValue}`
      )
      .then((res) => {
        setLoading(false);
        setProducts(res.data);
        setFilteredProducts(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(`${wcUrl}products/count?${ckcs}&${apiKey}`)
      .then((res) => setTotalCount(res.data.count))
      .catch((err) => console.log(err));
  }, []);
  const handleEdit = (product) => {
    setEditObj(product);
    setEditProduct(true);
    setCreateProduct(true);
  };
  return (
    <div className="container-scroller">
      {isLoading ? <Loader /> : ""}
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  {createProduct ? (
                    <CreateProduct
                      setCreateProduct={setCreateProduct}
                      createProduct={createProduct}
                      setIsLoading={setIsLoading}
                      setRefreshItems={setRefreshItems}
                      refreshItems={refreshItems}
                      editProduct={editProduct}
                      setEditProduct={setEditProduct}
                      editObj={editObj}
                      setEditObj={setEditObj}
                    />
                  ) : (
                    <div className="card-body">
                      <h4
                        className="card-title header-filter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Products
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          className="marginTop-10"
                        >
                          <div>
                            <div
                              className="input-group"
                              style={{ width: "400px" }}
                            >
                              <div
                                className="input-group-prepend hover-cursor"
                                id="navbar-search-icon"
                              >
                                <span className="input-group-text" id="search">
                                  <i className="icon-search" />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="navbar-search-input"
                                placeholder="Search now"
                                aria-label="search"
                                aria-describedby="search"
                                value={searchValue}
                                onChange={handleSearch}
                              />
                            </div>
                            {searchValue && searchValue.length < 3 ? (
                              <p className="text-red">
                                Search box must contain at least three letters
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className={
                              searchValue && searchValue.length < 3
                                ? "plusonsearch"
                                : "places"
                            }
                          >
                            <FaPlus
                              onClick={() => {
                                setCreateProduct(true);
                              }}
                              style={{ cursor: "pointer", float: "right" }}
                              className="filterIcon"
                            />
                          </div>
                        </div>
                      </h4>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              {headerData.map((item, index) => (
                                <th key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.name}
                                    {item.sortable ? (
                                      <SortIcons
                                        up={up}
                                        setUp={setUp}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        item={item}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan={5}>
                                  <div className="places">
                                    <Skeleton
                                      variant="pulse"
                                      width="100%"
                                      style={{ borderRadius: "5px" }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <>
                                {products &&
                                products?.length !== 0 &&
                                searchValue &&
                                searchValue.length >= 3
                                  ? products
                                      .slice(
                                        (page + 1 - 1) * rowsPerPage,
                                        (page + 1) * rowsPerPage
                                      )
                                      .map((product, key) => {
                                        return (
                                          <tr key={key}>
                                            <td
                                              className="py-1"
                                              data-label="Id"
                                            >
                                              {product.id}
                                            </td>
                                            <td data-label="Name">
                                              {product.name}
                                            </td>
                                            <td data-label="Price">
                                              {product.price}
                                            </td>
                                            <td data-label="Status">
                                              {product.status}
                                            </td>
                                            <td data-label="Actions">
                                              &nbsp;
                                              <a
                                                href={product.permalink}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ color: "#2E8B57" }}
                                              >
                                                <FaEye
                                                  style={{ cursor: "pointer" }}
                                                  title="View Product"
                                                />
                                              </a>
                                              &nbsp;&nbsp;&nbsp;
                                              <FaEdit
                                                title="Edit"
                                                style={{ cursor: "pointer" }}
                                                // onClick={() =>
                                                //   actions.updateProduct(product.id)
                                                // }
                                                onClick={() =>
                                                  handleEdit(product)
                                                }
                                              />
                                              &nbsp;&nbsp;&nbsp;
                                              <FaTrashAlt
                                                style={{ cursor: "pointer" }}
                                                title="Delete"
                                                onClick={() =>
                                                  Swal.fire({
                                                    title:
                                                      "Do you want to Delete the Product?",
                                                    showDenyButton: true,
                                                    showCancelButton: false,
                                                    confirmButtonText: `Delete`,
                                                    denyButtonText: `Cancel`,
                                                    confirmButtonColor: "#39f",
                                                    icon: "info",
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      actions.deleteProduct(
                                                        product.id,
                                                        refreshItems,
                                                        setRefreshItems,
                                                        setIsLoading
                                                      );
                                                    }
                                                  })
                                                }
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : products.map((product, key) => {
                                      return (
                                        <tr key={key}>
                                          <td className="py-1" data-label="Id">
                                            {product.id}
                                          </td>
                                          <td data-label="Name">
                                            {product.name}
                                          </td>
                                          <td data-label="Price">
                                            {product.price_max
                                              ? `Rs. ${product.price_min} - Rs. ${product.price_max}`
                                              : `${
                                                  product.price
                                                    ? `Rs. ${product.price}`
                                                    : ""
                                                }`}
                                          </td>
                                          <td data-label="Status">
                                            {product.status}
                                          </td>
                                          <td data-label="Actions">
                                            &nbsp;
                                            <a
                                              href={product.permalink}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ color: "#2E8B57" }}
                                            >
                                              <FaEye
                                                style={{ cursor: "pointer" }}
                                                title="View Product"
                                              />
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            <FaEdit
                                              title="Edit"
                                              style={{ cursor: "pointer" }}
                                              // onClick={() =>
                                              //   actions.updateProduct(product.id)
                                              // }
                                              onClick={() =>
                                                handleEdit(product)
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <FaTrashAlt
                                              style={{ cursor: "pointer" }}
                                              title="Delete"
                                              onClick={() =>
                                                Swal.fire({
                                                  title:
                                                    "Do you want to Delete the Product?",
                                                  showDenyButton: true,
                                                  showCancelButton: false,
                                                  confirmButtonText: `Delete`,
                                                  denyButtonText: `Cancel`,
                                                  confirmButtonColor: "#39f",
                                                  icon: "info",
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    actions.deleteProduct(
                                                      product.id,
                                                      refreshItems,
                                                      setRefreshItems,
                                                      setIsLoading
                                                    );
                                                  }
                                                })
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}{" "}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <TablePagination
                        component="div"
                        count={
                          searchValue && searchValue.length >= 3
                            ? products?.length
                            : `${totalCount ? totalCount : 0}`
                        }
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:../../partials/_footer.html */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a href="https://myonlinevipani.com" target="_blank">
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    productList: state.Products.productsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProductList: () => dispatch(actions.getClientList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
