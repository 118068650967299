import React, { useMemo, useState } from "react";
import { productCatOpt } from "../../data/data.json";
import { TablePagination } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import SortIcons from "../common/SortIcons";

const SaleCategory = ({ saleData, loading, catLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [up, setUp] = useState(true);
  const [sorting, setSorting] = useState({
    field: "Net_Sales_Amount",
    order: "asc",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const headerData = [
    {
      name: "Category",
      field: "category",
      numeric: false,
      sortable: true,
    },
    {
      name: "Products Sold",
      field: "No_of_Products_Sold",
      numeric: true,
      sortable: true,
    },
    {
      name: "Net Sales amount",
      field: "Net_Sales_Amount",
      numeric: true,
      sortable: true,
    },
  ];
  const commentsData = useMemo(() => {
    let computedComments = saleData;
    if (sorting.field) {
      computedComments = computedComments.sort((a, b) => {
        if (a[sorting.field] < b[sorting.field]) {
          return sorting.order === "asc" ? 1 : -1;
        } else if (a[sorting.field] > b[sorting.field]) {
          return sorting.order === "asc" ? -1 : 1;
        }
      });
    }
  }, [sorting, saleData]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="col-md-6 mb-2 mt-4 transparent">
      <div className="card card-dark-blue" style={{ height: "100%" }}>
        <div className="card-body">
          <p className="fs-30 mb-4">Sales By Category</p>
          {catLoading ? (
            <Stack spacing={1}>
              <Skeleton variant="text" width={"95%"} />
              <Skeleton variant="pulse" width={"95%"} height={118} />
              <Skeleton variant="text" width={"95%"} />
              <Skeleton variant="pulse" width={"95%"} height={118} />
            </Stack>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {headerData.map((item, index) => (
                        <th key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.name}
                            {item.sortable ? (
                              <SortIcons
                                up={up}
                                setUp={setUp}
                                sorting={sorting}
                                setSorting={setSorting}
                                item={item}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {saleData?.length !== 0 ? (
                      saleData
                        .slice(
                          (page + 1 - 1) * rowsPerPage,
                          (page + 1) * rowsPerPage
                        )
                        .map((item, i) => (
                          <tr key={i}>
                            <td data-label="Category">{item.category}</td>
                            <td data-label="products sold">
                              {item?.No_of_Products_Sold}
                            </td>
                            <td data-label="net sales">
                              Rs.{" "}
                              {item?.Net_Sales_Amount
                                ? item?.Net_Sales_Amount
                                : 0}
                              /-
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <div className="places">
                            {loading ? "Loading......" : "No Data Available"}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <TablePagination
                component="div"
                count={productCatOpt?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaleCategory;
