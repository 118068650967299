import axios from "axios";
import Swal from "sweetalert2";
import config, { apiKey, ckcs, devUrl, siteUrl } from "./common/ApiLinks";

export const getOrdersList = (loading, setOrdersList, setSortedOrdersList) => {
  loading(true);
  let startDate = new Date(Date.now() - 100 * 24 * 60 * 60 * 1000).toISOString();
  let endDate = new Date(Date.now()).toISOString();
  let url = `${siteUrl}all_orders?${ckcs}&api_key=${apiKey}`;
  axios
    .get(url)
    .then((res) => {
      setSortedOrdersList(res.data);
      setOrdersList(res.data);
      loading(false);
    })
    .catch((err) => {
      loading(false);
      console.log(err);
    });
};
export const getOrderDetailsById = (
  loading,
  id,
  setOrderData,
  setCurrentData,
  setProductRelOrder,
  setOriginalProducts
) => {
  loading(true);
  let url = `${siteUrl}orders/${id}?filter[meta]=true&${ckcs}&api_key=${apiKey}`;
  axios
    .get(url)
    .then((res) => {
      setCurrentData(res.data);
      setProductRelOrder(res?.data?.line_items);
      setOriginalProducts(res?.data?.line_items);
      setOrderData(res.data);
      loading(false);
    })
    .catch((err) => {
      loading(false);
      console.log(err);
    });
};
export const getOrdersListByStatus = (
  loading,
  setOrdersList,
  setSortedOrdersList,
  filterkey,
  val
) => {
  loading(true);
  let url = `${siteUrl}all_orders?${filterkey}=${val}&${ckcs}&api_key=${apiKey}`;
  axios
    .get(url)
    .then((res) => {
      setSortedOrdersList(res.data);
      setOrdersList(res.data);
      loading(false);
    })
    .catch((err) => {
      loading(false);
      console.log(err);
    });
};
export const handleSearchProducts = (query, setIsLoading, setOptions) => {
  setIsLoading(true);
  axios
    .get(`${siteUrl}products?search=${query}&per_page=50&${ckcs}&api_key=${apiKey}`)
    .then((items) => {
      const options = items.data.map((i) => ({
        id: i.id,
        login: i.name,
      }));
      setOptions(options);
      setIsLoading(false);
    });
};

export const updateOrder = (
  OrderId,
  data,
  refreshItems,
  setRefreshItems,
  setLoading,
  setIsViewOrder
) => {
  setLoading(true);
  axios
    .put(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, data)
    .then((res) => {
      setIsViewOrder(false);
      setLoading(false);
      Swal.fire({
        text: "Order updated Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const updateDeliveryDate = (
  OrderId,
  data,
  refreshItems,
  setRefreshItems,
  setLoading
) => {
  setLoading(true);
  let url = `setdeliverydate/${OrderId}?api_key=${apiKey}&delivery_date=${data}`;
  axios
    .get(`${siteUrl}${url}`)
    .then((res) => {
      setLoading(false);
      Swal.fire({
        text: "Order updated Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getAllCountriesData = (setIsLoading, setCountries) => {
  setIsLoading(true);
  axios
    .get(`${siteUrl}data/countries?${ckcs}&api_key=${apiKey}`)
    .then((res) => {
      setIsLoading(false);
      setCountries(
        res?.data?.map((opt) => ({ label: opt.name, value: opt.code }))
      );
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};
export const getAllStatesData = (setIsLoading, setCountries, data) => {
  setIsLoading(true);
  let ct = data.value;
  axios
    .get(`${siteUrl}data/countries/${ct}?${ckcs}&api_key=${apiKey}`)
    .then((res) => {
      setIsLoading(false);
      setCountries(
        res?.data?.states?.map((opt) => ({ label: opt.name, value: opt.code }))
      );
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};
export const updateShippingAddress = (
  OrderId,
  data,
  refreshItems,
  setRefreshItems,
  setLoading,
  setToggleShipping
) => {
  setLoading(true);
  axios
    .put(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, data)
    .then((res) => {
      setLoading(false);
      setToggleShipping(false)
      Swal.fire({
        text: "Order updated Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const updateShippingCharge = (
  OrderId,
  data,
  refreshItems,
  setRefreshItems,
  setLoading,
  setToggleShipping
) => {
  setLoading(true);
  axios
    .put(`${siteUrl}orders/${OrderId}?${ckcs}&api_key=${apiKey}`, data)
    .then((res) => {
      setLoading(false);
      setToggleShipping(false)
      Swal.fire({
        text: "Shipping Method Added Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getAllShippingMethodsData = (setIsLoading, setMethods) => {
  setIsLoading(true);
  axios
    .get(`${siteUrl}shipping_methods?${ckcs}&api_key=${apiKey}`)
    .then((res) => {
      setIsLoading(false);
      setMethods(
        res?.data?.map((opt) => ({ label: opt.title, value: opt.id }))
      );
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};
export const getDeliveryDate = (id, setLoading, setDate) => {
  setLoading(true)
  let url = `https://dev.myonlinevipani.com/wc-api/v3/orders/${id}?${ckcs}&api_key=${apiKey}`;
  axios.get(url).then(res => {
    setLoading(false)
    setDate(res.data)
  }).catch(err => {
    setLoading(false)
    console.log(err)
  })
}
export const updateStaffAndFCenter = (id, setLoading, data) => {
  setLoading(true)
  let url = `https://dev.myonlinevipani.com/wc-api/v3/orders/${id}?${ckcs}`;
  axios.put(`${siteUrl}orders/${id}?${ckcs}&api_key=${apiKey}`, data).then(res => {
    setLoading(false)
  }).catch(err => {
    setLoading(false)
    console.log(err)
  })
}
export const createARefund = (id, setLoading, data, refreshItems, setRefreshItems, setIsrefund) => {
  setLoading(true)
  axios.post(`${siteUrl}orders/${id}/refunds?${ckcs}&api_key=${apiKey}`, data).then(res => {
    setLoading(false);
    Swal.fire({
      text: 'refund added successfully',
      icon: 'success',
      allowClickOutside: false
    }).then(res => {
      if(res.isConfirmed){
        setIsrefund(false)
        setRefreshItems(!refreshItems);
      }
    })
  }).catch(err => {
    setLoading(false)
    console.log(err)
  })
}
export const createAFullRefund = (id, setLoading, data, refreshItems, setRefreshItems, setIsrefund) => {
  setLoading(true)
  axios.post(`${siteUrl}orders/${id}/refunds?${ckcs}&api_key=${apiKey}`, data).then(res => {
    setLoading(false);
    Swal.fire({
      text: 'refund added successfully',
      icon: 'success',
      allowClickOutside: false
    }).then(res => {
      if(res.isConfirmed){
        const data = {
          status: 'refunded',
        };
        setLoading(true)
        axios
    .put(`${siteUrl}orders/${id}?${ckcs}&api_key=${apiKey}`, data)
    .then((res) => {
      setLoading(false);
      Swal.fire({
        text: "Order updated Successfully.",
        icon: "success",
        allowOutsideClick:false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
        setIsrefund(false)
        setRefreshItems(!refreshItems);
      }
    })
  }).catch(err => {
    setLoading(false)
    console.log(err)
  })
}
export const getOrdersStatisticsByCenters = (setLoading, setData) => {
  setLoading(true)
  axios.get(`${siteUrl}order_groups?${ckcs}&api_key=${apiKey}`).then(res=>{
    setLoading(false)
    setData(res.data);
  }).catch(err => {
    setLoading(false)
    setData([])
  })
}