import axios from "axios";
import config, { apiKey, ckcs, devUrl, siteUrl } from "./common/ApiLinks";

export const getSummaryReports = (startDate, endDate, setLoading, setData) => {
  setLoading(true);
  axios
    .get(
      `${siteUrl}reports/sales?date_min=${startDate}&date_max=${endDate}&${ckcs}&api_key=${apiKey}`
    )
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getSalesByCategory = (startDate, endDate, setLoading, setData) => {
  setLoading(true);
  axios
    .get(
      `${siteUrl}sales_by_category?date_min=${startDate}&date_max=${endDate}&${ckcs}&api_key=${apiKey}`
    )
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getSalesByCenter = (startDate, endDate, setLoading, setData) => {
  setLoading(true);
  axios
    .get(
      `${siteUrl}sales_by_center?date_min=${startDate}&date_max=${endDate}&${ckcs}&api_key=${apiKey}`
    )
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getSalesByProducts = (
  startDate,
  endDate,
  setLoading,
  setData,
  size,
  number
) => {
  setLoading(true);
  axios
    .get(
      `${siteUrl}sales_by_staff?date_min=${startDate}&date_max=${endDate}&page_size=${size}&page_number=${number}&${ckcs}&api_key=${apiKey}`
    )
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export const getTopSellerReports = (
  startDate,
  endDate,
  setLoading,
  setData
) => {
  setLoading(true);
  axios
    .get(
      `${siteUrl}reports/top_sellers?date_min=${startDate}&date_max=${endDate}&${ckcs}&api_key=${apiKey}`
    )
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
