import React, { useEffect, useMemo, useState } from "react";
import { TablePagination } from "@material-ui/core";
import { moviDb } from "../../firebase";
import SortIcons from "../common/SortIcons";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SaleCenter = ({ centerData, loading, centerLoading }) => {
  const [page, setPage] = React.useState(0);
  const [fulfillCenters, setFulfillCenters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [up, setUp] = useState(true);
  const [sorting, setSorting] = useState({
    field: "Net_Sales_Amount",
    order: "asc",
  });
  const headerData = [
    {
      name: "Fulfillment center",
      field: "category",
      numeric: false,
      sortable: true,
    },
    {
      name: "Total Orders",
      field: "No_of_Products_Sold",
      numeric: true,
      sortable: true,
    },
    {
      name: "Net Sales amount",
      field: "Net_Sales_Amount",
      numeric: true,
      sortable: true,
    },
  ];
  const commentsData = useMemo(() => {
    let computedComments = centerData;
    if (sorting.field) {
      computedComments = computedComments.sort((a, b) => {
        if (a[sorting.field] < b[sorting.field]) {
          return sorting.order === "asc" ? 1 : -1;
        } else if (a[sorting.field] > b[sorting.field]) {
          return sorting.order === "asc" ? -1 : 1;
        }
      });
    }
  }, [sorting, centerData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(centerData);
  useEffect(() => {
    getFFcenters();
  }, []);
  const getFFcenters = () => {
    moviDb
      .collection("fulfillment_centers")
      .get()
      .then((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) =>
          postData.push({
            label: doc.data().fc_name,
            value: doc.data().fc_name,
          })
        );
        setFulfillCenters(postData);
      });
  };
  return (
    <div className="card card-dark-blue mt-2">
      <div className="card-body">
        <p className="fs-30 mb-4">Sales By Fulfillment centers</p>
        {centerLoading ? (
          <Stack spacing={1}>
            <Skeleton variant="text" width={"95%"} />
            <Skeleton variant="pulse" width={"95%"} height={118} />
          </Stack>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {headerData.map((item, index) => (
                      <th key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.name}
                          {item.sortable ? (
                            <SortIcons
                              up={up}
                              setUp={setUp}
                              sorting={sorting}
                              setSorting={setSorting}
                              item={item}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {centerData?.length !== 0 ? (
                    centerData
                      .slice(
                        (page + 1 - 1) * rowsPerPage,
                        (page + 1) * rowsPerPage
                      )
                      .map((item, i) => (
                        <tr key={i}>
                          <td data-label="Category">
                            {item.fulfillment_center}
                          </td>
                          <td data-label="products sold">
                            {item?.Total_Orders}
                          </td>
                          <td data-label="net sales">
                            Rs.{" "}
                            {item?.Net_Sales_Amount
                              ? item?.Net_Sales_Amount
                              : 0}
                            /-
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div className="places">
                          {loading ? "Loading......" : "No Data Available"}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <TablePagination
              component="div"
              count={centerData?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SaleCenter;
