import React, { useContext } from "react";
import { connect } from "react-redux";
import projectContext from "../context/App.Context";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import ProductsSale from "./salesByProducts/ProductsSale";

function SalesByProducts() {
  const { sidebarStatus } = useContext(projectContext);
  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            <ProductsSale />
          </div>

          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a href="https://myonlinevipani.com" target="_blank">
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SalesByProducts);
