import React, { useEffect, useState } from "react";
import {
  FaTimesCircle,
  FaTimes,
  FaPlus,
  FaTrashAlt,
  FaEdit,
} from "react-icons/fa";
import Editor from "./common/Editor";
import Select from "react-select";
import {
  getProductZipcode,
  getZipcodes,
  postImage,
  postProduct,
  postVarProduct,
  updateProduct,
} from "../actions/Products";
import { Checkbox } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import { moviDb } from "../firebase";
import Swal from "sweetalert2";
import axios from "axios";
import { apiKey, ckcs, siteUrl } from "../actions/common/ApiLinks";

const CreateProduct = ({
  setCreateProduct,
  setIsLoading,
  setRefreshItems,
  refreshItems,
  editProduct,
  setEditProduct,
  editObj,
  setEditObj,
  createProduct,
}) => {
  const renderStatus = (value) => {
    switch (value) {
      case "taxable":
        return { value: "taxable", label: "Taxable" };
      case "shipping":
        return { value: "shipping", label: "Shipping Only" };
      case "none":
        return { value: "none", label: "None" };
      default:
        return "";
    }
  };
  const renderZipcode = (value) => {
    switch (value) {
      case 1:
        return { value: 1, label: "Kakkamoola" };
      case 3:
        return { value: 3, label: "Trivandrum" };
      case 4:
        return { value: 4, label: "Nedumangad" };
      default:
        return null;
    }
  };
  const renderStockStatus = (value) => {
    switch (value) {
      case "instock":
        return { value: "instock", label: "In Stock" };
      case "outofstock":
        return { value: "outofstock", label: "Out Of Stock" };
      case "onbackborder":
        return { value: "onbackborder", label: "On Back Border" };
      default:
        return "";
    }
  };
  const renderClass = (value) => {
    switch (value) {
      case "Standard":
        return { value: "Standard", label: "Standard" };
      case "gst-0":
        return { value: "gst-0", label: "GST 0" };
      case "gst-12":
        return { value: "gst-12", label: "GST 12" };
      case "gst-18":
        return { value: "gst-18", label: "GST 18" };
      case "gst-5":
        return { value: "gst-5", label: "GST 5" };
      case "Reduced Rate":
        return { value: "Reduced Rate", label: "Reduced Rate" };
      case "Zero Rate":
        return { value: "Zero Rate", label: "Zero Rate" };
      default:
        return "";
    }
  };
  const [showCatageories, setShowCatageories] = useState(false);
  const [productName, setProductName] = useState(editObj?.name);
  const [regular_price, setRegularPrice] = useState(editObj?.regular_price);
  const initialData = {
    sku: "",
    mal_title: "",
    purch_title: "",
    eng_title: "",
  };
  const [skuData, setSkuData] = useState(initialData);
  const [originalSkuData, setOriginalSkuData] = useState(initialData);
  const [isVarProduct, setisVarProduct] = useState(false);
  const [salePrice, setSalePrice] = useState(
    editObj?.sale_price ? editObj?.sale_price : ""
  );
  const [productDescription, setProductDescription] = useState(
    editObj?.description
  );
  const [productShortDescription, setProductShortDescription] = useState(
    editObj?.short_description
  );
  const [productImageUrl, setProductImageUrl] = useState(
    editObj?.images
      ? editObj?.images?.length == 0
        ? ""
        : editObj?.images[0]?.src
      : ""
  );
  const [displayImage, setDisplayImage] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [attributesArr, setAttributesArr] = useState([
    { name: "", id: 0, label: "", value: "" },
  ]);
  const [attrName, setAttrName] = useState("");
  const [showVari, setShowVari] = useState(false);
  const [varProData, setVarProData] = useState(null);
  const [varArr, setVarArr] = useState([
    {
      regular_price: "",
      sale_price: "",
      opt: null,
      id: 0,
      varId: 0,
    },
  ]);
  const [variationsArr, setVariationsArr] = useState([]);
  const filterArr = () => {
    let arr = [...attributesArr];
    let a = [];
    arr.forEach((item) => {
      a.push(item.name);
    });
    return a;
  };
  useEffect(() => {
    if (productImageUrl) {
      let url = productImageUrl;
      let number = url.split("/").length;
      let name = url.split("/")[number - 1];
      setDisplayName(name);
      setDisplayImage(false);
    } else {
      setDisplayImage(true);
    }
  }, [productImageUrl]);

  const [zipcodeGroup, setZipCodeGroup] = useState(null);
  const [taxStatus, setTaxstatus] = useState(
    editObj?.tax_status
      ? renderStatus(editObj?.tax_status)
      : {
          value: "taxable",
          label: "Taxable",
        }
  );
  const [taxClass, setTaxClass] = useState(
    editObj?.tax_class
      ? renderClass(editObj?.tax_class)
      : { value: "gst-0", label: "GST 0" }
  );
  const [productCatageory, setProductCatageory] = useState([]);
  const [errors, setErrors] = useState({
    NameError: "",
    regularPerror: "",
    salePerror: "",
    productDesError: "",
    productShortDesError: "",
    imageError: "",
    zipcodeError: "",
    taxClassError: "",
    taxStatusError: "",
    productCatError: "",
    stockQuantityError: "",
  });
  const [meanToggle, setMeanToggle] = useState(editObj?.manage_stock);
  const [stock_status, setStockStatus] = useState(
    editObj?.stock_status
      ? renderStockStatus(editObj?.stock_status)
      : {
          value: "instock",
          label: "In Stock",
        }
  );
  const [stockQuantity, setStockQuantity] = useState(
    editObj?.stock_quantity ? editObj?.stock_quantity : ""
  );
  const [selectedCat, setSelectedCat] = useState(
    editObj?.categories ? editObj?.categories : []
  );
  const [zip, setZip] = useState([]);
  const [productZipcodes, setProductZipcodes] = useState([]);

  const addCatageory = (selectedList, selectedItem) => {
    setSelectedCat(selectedList);
  };
  useEffect(() => {
    getZipcodes(setIsLoading, setZip);
  }, []);
  useEffect(() => {
    if (editProduct) {
      getProductZipcode(editObj.id, zip, setZipCodeGroup, setIsLoading);
    }
  }, [editProduct, zip]);
  const taxstatuses = [
    { value: "taxable", label: "Taxable" },
    { value: "shipping", label: "Shipping Only" },
    { value: "none", label: "None" },
  ];
  const taxclasses = [
    { value: "Standard", label: "Standard" },
    { value: "gst-0", label: "GST 0" },
    { value: "gst-12", label: "GST 12" },
    { value: "gst-18", label: "GST 18" },
    { value: "gst-5", label: "GST 5" },
    { value: "Reduced Rate", label: "Reduced Rate" },
    { value: "Zero Rate", label: "Zero Rate" },
  ];
  const stockStatuses = [
    { value: "instock", label: "In Stock" },
    { value: "outofstock", label: "Out Of Stock" },
    { value: "onbackborder", label: "On Back Border" },
  ];

  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };
  const validate = () => {
    let NameError = "";
    let regularPerror = "";
    let imageError = "";
    let zipcodeError = "";
    let taxClassError = "";
    let taxStatusError = "";
    let productCatError = "";
    let stockQuantityError = "";

    if (!productName) {
      NameError = "*Name is Required";
    }
    if (!isVarProduct) {
      if (!regular_price) {
        regularPerror = "*Price is Required";
      }
    }
    if (!productCatageory) {
      productCatError = "*Product catageory is Required";
    }
    if (meanToggle && stockQuantity == "") {
      stockQuantityError = "*Enter Stock Quantity";
    }
    if (!productImageUrl) {
      imageError = "*Please Upload a Image";
    }
    if (zipcodeGroup == null || zipcodeGroup.value == "") {
      zipcodeError = "*Please select zipcode group";
    }
    if (
      NameError ||
      regularPerror ||
      productCatError ||
      stockQuantityError ||
      imageError ||
      zipcodeError
    ) {
      setErrors({
        ...errors,
        NameError,
        regularPerror,
        productCatError,
        stockQuantityError,
        imageError,
        zipcodeError,
      });
      return false;
    } else {
      setErrors({
        ...errors,
        NameError: "",
        regularPerror: "",
        productCatError: "",
        stockQuantityError: "",
        zipcodeError: "",
        imageError: "",
      });
    }
    return true;
  };
  const handleSubmit = () => {
    const data = {
      name: productName,
      type: isVarProduct ? "variable" : "simple",
      status: "publish",
      regular_price: regular_price,
      sale_price: salePrice,
      description: productDescription,
      short_description: productShortDescription,
      categories: productCatageory,
      images: [{ src: productImageUrl }],
      tax_class: taxClass?.value,
      tax_status: taxStatus?.value,
      manage_stock: meanToggle,
      zipcode_group: productZipcodes,
    };
    let product = {};
    if (meanToggle) {
      product = { ...data, stock_quantity: stockQuantity };
    } else {
      product = { ...data, stock_status: stock_status.value };
    }
    if (editProduct || showVari) {
      const isValid = true;
      if (isVarProduct) {
        product = {
          ...product,
          attributes: [
            {
              name: attrName,
              visible: true,
              has_archives: false,
              variation: true,
              options: filterArr(),
            },
          ],
        };
      } else {
        product = {
          ...product,
          attributes: [],
        };
      }
      if (isValid) {
        updateProduct(
          editObj?.id,
          product,
          setIsLoading,
          setRefreshItems,
          refreshItems,
          setCreateProduct,
          productZipcodes,
          setEditObj,
          setEditProduct,
          setVarProData,
          setShowVari
        );
      }
    } else {
      if (isVarProduct) {
        product = {
          ...product,
          attributes: [
            {
              name: attrName,
              visible: true,
              has_archives: false,
              variation: true,
              options: filterArr(),
            },
          ],
        };
      } else {
        product = {
          ...product,
          attributes: [],
        };
      }
      const isValid = validate();
      if (isValid) {
        postProduct(
          product,
          setIsLoading,
          setRefreshItems,
          refreshItems,
          setCreateProduct,
          productZipcodes,
          setEditObj,
          setEditProduct,
          setVarProData,
          setShowVari
        );
      }
    }
  };
  const handleDraft = () => {
    const data = {
      name: productName,
      type: isVarProduct ? "variable" : "simple",
      regular_price: regular_price,
      sale_price: salePrice,
      status: "draft",
      description: productDescription,
      short_description: productShortDescription,
      categories: productCatageory,
      images: [{ src: productImageUrl }],
      tax_class: taxClass?.value,
      tax_status: taxStatus?.value,
      manage_stock: meanToggle,
    };
    let product = {};
    if (meanToggle) {
      product = { ...data, stock_quantity: stockQuantity };
    } else {
      product = { ...data, stock_status: stock_status.value };
    }
    if (editProduct) {
      const isValid = true;
      if (isVarProduct) {
        product = {
          ...product,
          attributes: [
            {
              name: attrName,
              visible: true,
              has_archives: false,
              variation: true,
              options: filterArr(),
            },
          ],
        };
      } else {
        product = {
          ...product,
          attributes: [],
        };
      }
      if (isValid) {
        updateProduct(
          editObj?.id,
          product,
          setIsLoading,
          setRefreshItems,
          refreshItems,
          setCreateProduct,
          productZipcodes,
          setEditObj,
          setEditProduct,
          setVarProData,
          setShowVari
        );
      }
    } else {
      const isValid = validate();
      if (isVarProduct) {
        product = {
          ...data,
          attributes: [
            {
              name: attrName,
              visible: true,
              has_archives: false,
              variation: true,
              options: filterArr(),
            },
          ],
        };
      } else {
        product = {
          ...data,
          attributes: [],
        };
      }
      if (isValid) {
        postProduct(
          product,
          setIsLoading,
          setRefreshItems,
          refreshItems,
          setCreateProduct,
          productZipcodes,
          setEditObj,
          setEditProduct,
          setVarProData,
          setShowVari
        );
      }
    }
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("title", file.name);
    postImage(formdata, setIsLoading, setProductImageUrl);
  };
  const productCatOpt = [
    {
      name: "Daily Needs",
      id: 936,
    },
    {
      name: "Diwali",
      id: 1151,
    },
    {
      name: "Navaratri",
      id: 1146,
    },
    {
      name: "Pantry",
      id: 937,
    },
    {
      name: "Cut Vegitables",
      id: 42,
    },
    {
      name: "Nandan",
      id: 15,
    },
    {
      name: "Fruits",
      id: 797,
    },
    {
      name: "Meat",
      id: 798,
    },
    {
      name: "Vegitables",
      id: 796,
    },
    {
      name: "Baby And Kids",
      id: 51,
    },
    {
      name: "Bakery",
      id: 47,
    },
    {
      name: "Biscuits",
      id: 921,
    },
    {
      name: "Bread",
      id: 917,
    },
    {
      name: "Cake",
      id: 918,
    },
    {
      name: "Chocolates",
      id: 920,
    },
    {
      name: "Snacks",
      id: 919,
    },
    {
      name: "Sweets",
      id: 1152,
    },
    {
      name: "Bevarages",
      id: 48,
    },
    {
      name: "COVID-19",
      id: 604,
    },
    {
      name: "Diary",
      id: 38,
    },
    {
      name: "Essentials",
      id: 49,
    },
    {
      name: "Oil",
      id: 968,
    },
    {
      name: "Fresh Fruits",
      id: 37,
    },
    {
      name: "Grains",
      id: 39,
    },
    {
      name: "Household",
      id: 52,
    },
    {
      name: "Cleaners",
      id: 850,
    },
    {
      name: "Detergent",
      id: 851,
    },
    {
      name: "Dishwash",
      id: 852,
    },
    {
      name: "Utilities",
      id: 853,
    },
    {
      name: "Meat",
      id: 53,
    },
    {
      name: "Beef",
      id: 792,
    },
    {
      name: "Chicken",
      id: 791,
    },
    {
      name: "Fish",
      id: 793,
    },
    {
      name: "Goat",
      id: 794,
    },
    {
      name: "Personali Care",
      id: 50,
    },
    {
      name: "Baby and Bath",
      id: 887,
    },
    {
      name: "Cosmetics",
      id: 889,
    },
    {
      name: "Faminine Hygiene",
      id: 890,
    },
    {
      name: "Hair Care",
      id: 891,
    },
    {
      name: "Health Care",
      id: 892,
    },
    {
      name: "Mens Grooming",
      id: 893,
    },
    {
      name: "Oral Care",
      id: 894,
    },
    {
      name: "Perfumes",
      id: 888,
    },
    {
      name: "Rice and Flour",
      id: 40,
    },
    {
      name: "Spices",
      id: 41,
    },
    {
      name: "Chilli",
      id: 789,
    },
    {
      name: "Masala",
      id: 785,
    },
    {
      name: "Raw Spices",
      id: 790,
    },
    {
      name: "Vegitables",
      id: 27,
    },
    {
      name: "Nandan",
      id: 795,
    },
  ];
  useEffect(() => {
    if (selectedCat?.length !== 0) {
      let arr = [...selectedCat];
      let a = [];
      arr.map((item) => {
        let b = {
          id: item.id,
        };
        a.push(b);
      });
      setProductCatageory(a);
    }
  }, [selectedCat]);
  useEffect(() => {
    if (zipcodeGroup?.length > 0) {
      let zips = [];
      zipcodeGroup?.map((zip) => zips.push(Number(zip.value)));
      setProductZipcodes(zips);
    }
  }, [zipcodeGroup]);
  const getSku = (id) => {
    moviDb
      .collection("products")
      .doc(id)
      .get()
      .then((docRef) => {
        if (docRef.data()) {
          setSkuData(docRef.data());
          setOriginalSkuData(docRef.data());
        } else {
          setSkuData(initialData);
        }
      });
  };
  useEffect(() => {
    if (editProduct) {
      getSku(editObj?.sku);
    }
    if (editObj?.type == "variable") {
      if (editObj?.attributes.length !== 0) {
        setAttrName(editObj?.attributes[0].name);
        // { name: "", id: 0, label: "", value: "" },
        if (editObj?.attributes[0].options.length !== 0) {
          let arr = [];
          editObj?.attributes[0].options.map((item, i) => {
            let data = { name: item, id: i, label: item, value: item };
            arr.push(data);
          });
          setAttributesArr(arr);
        }
      }
      setisVarProduct(true);
      setVarArr([]);
      setShowVari(true);
      getProductVariations();
    } else {
      setisVarProduct(false);
    }
  }, [editProduct]);
  const getProductVariations = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${siteUrl}products/${editObj?.id}/variations?${ckcs}&api_key=${apiKey}`
      )
      .then((res) => {
        setIsLoading(false);
        let arr = [];
        if (res.data.length !== 0) {
          res.data.map((vari, i) => {
            let data = {
              regular_price: vari?.regular_price,
              sale_price: vari?.sale_price,
              opt: {
                value: vari.attributes[0]?.option,
                label: vari.attributes[0]?.option,
              },
              id: i,
              varId: vari?.id,
            };
            arr.push(data);
          });
        }
        setVariationsArr(arr);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const updateSkuData = (id) => {
    setIsLoading(true);
    if (skuData?.sku) {
      let data = {
        ...skuData,
      };
      moviDb
        .collection("products")
        .doc(`${skuData.sku}`)
        .update(data)
        .then((res) => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            text: "Product title updated successfully",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            text: "Error On updating data",
          });
          console.log(err);
        });
    } else {
      let data = {
        ...skuData,
        sku: editObj?.sku,
      };
      moviDb
        .collection("products")
        .doc(`${editObj?.sku}`)
        .set(data)
        .then((res) => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            text: "product title updated successfully",
          }).then((res) => {
            setIsLoading(false);
            setRefreshItems(!refreshItems);
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const handleVariationsCheck = (e) => {
    if (e.target.checked) {
      Swal.fire({
        icon: "info",
        text: "Does your product having Variations?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          setisVarProduct(true);
        } else {
          setisVarProduct(false);
        }
      });
    } else {
      setisVarProduct(false);
    }
  };
  const handleAttValue = (evt, ind) => {
    let arr = [...attributesArr];
    arr[ind].name = evt.target.value;
    arr[ind].label = evt.target.value;
    arr[ind].value = evt.target.value;
    setAttributesArr(arr);
  };
  const handleSaveVariations = (id) => {
    let arr = [...varArr];
    let data = [];
    arr.map((item, i) => {
      let a = {
        regular_price: item.regular_price,
        sale_price: item.sale_price,
        sku: `${Date.now()}${i}`,
        attributes: [
          {
            option: item.opt?.value,
            name: attrName,
          },
        ],
      };
      data.push(a);
    });
    let a1 = data.filter((ite) => ite?.regular_price === "");
    let a2 = arr.filter((ite) => ite.opt === "");
    if (a1.length === 0 && a2.length === 0) {
      const d = { create: data };
      setIsLoading(true);
      axios
        .post(
          `${siteUrl}products/${
            varProData?.id || editObj?.id
          }/variations/batch?${ckcs}&api_key=${apiKey}`,
          d
        )
        .then((res) => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            text: "Variation(s) Added",
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              setEditObj({});
              setCreateProduct(false);
            }
          });
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire({ icon: "error", text: "Something went wrong" });
        });
    } else {
      Swal.fire({
        icon: "info",
        text: "Variation type and Regular price required",
        allowOutsideClick: false,
      });
    }
  };
  return (
    <div className="card-body">
      <h4
        className="card-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {editProduct ? "Edit Product" : "Create Product"}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <FaTimes
            onClick={() => {
              setCreateProduct(false);
              setEditProduct(false);
              setEditObj({});
            }}
            style={{ cursor: "pointer", float: "right" }}
          />
        </div>
      </h4>
      <div className="row">
        <div className="col-sm-6 mb-6">
          <label htmlFor="cc-name">Product Name</label>
          <input
            className="form-control"
            placeholder="Product Name"
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.NameError}
          </div>
        </div>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Regular Price</label>
          <input
            className="form-control"
            type="text"
            placeholder="Regular Price"
            value={regular_price}
            onChange={(e) => setRegularPrice(e.target.value)}
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.regularPerror}
          </div>
        </div>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Sale Price</label>
          <input
            className="form-control"
            type="text"
            placeholder="Sale Price"
            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.salePerror}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 mb-6">
          <label htmlFor="cc-name">Product Description</label>
          <Editor
            setEditorValue={setProductDescription}
            editorValue={productDescription}
            editProduct={editProduct}
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.productDesError}
          </div>
        </div>
        <div className="col-sm-6 mb-6">
          <label htmlFor="cc-name">Product Short Description</label>
          <Editor
            setEditorValue={setProductShortDescription}
            editorValue={productShortDescription}
            editProduct={editProduct}
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.productShortDesError}
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Product Image</label>
          <input
            className={
              displayImage ? "form-control" : "form-control noimageDisplay"
            }
            placeholder="Card holder Name"
            type="file"
            onChange={handleImage}
          />
          {productImageUrl ? (
            <div className="previewOfImage">
              <a target="_blank" href={productImageUrl} className="greenText">
                {displayName}
              </a>
              <FaTimesCircle
                className="cursorPointer"
                onClick={() => setProductImageUrl("")}
              />
            </div>
          ) : (
            ""
          )}
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.imageError}
          </div>
        </div>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Zipcode Group</label>
          <Select
            options={zip}
            value={zipcodeGroup}
            onChange={(value) => {
              setZipCodeGroup(value);
            }}
            isMulti
            class="form-control form-control-sm"
            placeholder="Select Zipcode Group"
            name="zipcode"
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.zipcodeError}
          </div>
        </div>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Tax Status</label>
          <Select
            options={taxstatuses}
            value={taxStatus}
            onChange={(event) => setTaxstatus(event)}
            class="form-control form-control-sm"
            placeholder="Select Tax status"
            name="taxstatus"
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.taxStatusError}
          </div>
        </div>
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Tax Class</label>
          <Select
            options={taxclasses}
            value={taxClass}
            onChange={(event) => setTaxClass(event)}
            class="form-control form-control-sm"
            placeholder="Select Tax Class"
            name="taxclass"
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.taxClassError}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 mb-3">
          <label htmlFor="cc-name">Product Catageories</label>
          <Multiselect
            displayValue="name"
            placeholder="Select Product Catageory"
            onRemove={(selectedList) => setSelectedCat(selectedList)}
            onSearch={function noRefCheck() {}}
            onSelect={addCatageory}
            options={productCatOpt}
            selectedValues={selectedCat}
            showCheckbox
          />
          <div className="invalid-field" style={{ color: "red" }}>
            {errors.productCatError}
          </div>
        </div>
        <div className="col-sm-5 mb-3">
          <label htmlFor="cc-name">Manage Stock?</label>
          <div className="form-control-sm">
            <Checkbox
              checked={meanToggle}
              onChange={(e) => setMeanToggle(e.target.checked)}
            />
            Enable stock management at product level
          </div>
        </div>
        {meanToggle ? (
          <div className="col-sm-3 mb-3">
            <label htmlFor="cc-name">Stock Quantity</label>
            <input
              className="form-control"
              type="text"
              placeholder="Stock Quantity"
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
            />
            <div className="invalid-field" style={{ color: "red" }}>
              {errors.stockQuantityError}
            </div>
          </div>
        ) : (
          <div className="col-sm-3 mb-3">
            <label htmlFor="cc-name">Stock Status</label>
            <Select
              options={stockStatuses}
              value={stock_status}
              onChange={(event) => setStockStatus(event)}
              class="form-control form-control-sm"
              placeholder="Select stock status"
              name="stockstatus"
            />
          </div>
        )}
      </div>
      <div className="pb-3">
        <input
          type="checkbox"
          onChange={handleVariationsCheck}
          checked={isVarProduct}
        />{" "}
        Is Product having variations?
        {/* {isVarProduct ? <button className="btn-outline-primary rounded-pill ml-3">
        Add Attribute
      </button> : ''} */}
      </div>
      {isVarProduct ? (
        <div className="row">
          <div className="col-sm-3 mb-3">
            <label htmlFor="cc-name">Attribute Name</label>
            <input
              type="text"
              className="form-control"
              value={attrName}
              onChange={(e) => setAttrName(e.target.value)}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {isVarProduct ? (
        <div className="row">
          <div className="col-sm-4 mb-3">
            <label htmlFor="cc-name" className="d-flex pb-2">
              Attribute Value(s){" "}
              <button
                className="btn btn-outline-primary rounded-pill ml-2"
                style={{
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  let newRow = {
                    name: "",
                    id: attributesArr.length + 1,
                    label: "",
                    value: "",
                  };
                  let arr = [...attributesArr];
                  arr.push(newRow);
                  setAttributesArr(arr);
                }}
              >
                {" "}
                Add value
                <FaPlus />
              </button>
              {editProduct && (
                <button
                  className="btn btn-outline-primary rounded-pill ml-1"
                  style={{
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    if (editObj?.attributes.length !== 0) {
                      setAttrName(editObj?.attributes[0].name);
                      // { name: "", id: 0, label: "", value: "" },
                      if (editObj?.attributes[0].options.length !== 0) {
                        let arr = [];
                        editObj?.attributes[0].options.map((item, i) => {
                          let data = {
                            name: item,
                            id: i,
                            label: item,
                            value: item,
                          };
                          arr.push(data);
                        });
                        setAttributesArr(arr);
                      }
                    }
                  }}
                >
                  {" "}
                  Cancel update
                  <FaTimes />
                </button>
              )}
            </label>
            {attributesArr.map((item, i) => (
              <div className="mt-1 d-flex" key={i}>
                <input
                  type="text"
                  className="form-control"
                  value={item.name}
                  onChange={(e) => handleAttValue(e, i)}
                />
                <FaTrashAlt
                  fontSize="20px"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  title="Remove"
                  className="cursor-pointer"
                  onClick={() => {
                    if (attributesArr.length > 1) {
                      let arr = [...attributesArr];
                      arr = arr.filter((dobj) => dobj.id !== item.id);
                      setAttributesArr(arr);
                    } else {
                      Swal.fire({
                        text: "Atleast one value required",
                        allowOutsideClick: false,
                      });
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className="row"
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {editProduct || showVari ? (
          ""
        ) : (
          <button
            class="btn btn-outline-info rounded-pill mb-3"
            type="submit"
            onClick={handleDraft}
          >
            Save As Draft
          </button>
        )}

        <div className="col-sm-2 mb-3">
          <button
            class="btn btn-primary  rounded-pill"
            type="submit"
            onClick={handleSubmit}
          >
            {editProduct || showVari ? "Update" : "Create"}
          </button>
        </div>
      </div>
      {showVari ? (
        <>
          <div className="mb-3">
            <div className="row">
              <div className="col-sm-3 ">
                <label htmlFor="cc-name">Add a Variation</label>
                <div className="d-flex">
                  <button
                    className="btn btn-outline-primary rounded-pill"
                    style={{
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      let arr = [...varArr];
                      arr.push({
                        regular_price: "",
                        sale_price: "",
                        opt: "",
                        id: arr.length,
                        varId: 0,
                      });
                      setVarArr(arr);
                    }}
                  >
                    {" "}
                    Add a Variation
                    <FaPlus />
                  </button>
                  <button
                    className="btn btn-outline-primary rounded-pill ml-2"
                    style={{
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      getProductVariations();
                    }}
                  >
                    {" "}
                    cancel update
                    <FaTimes />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {variationsArr &&
            variationsArr.length !== 0 &&
            variationsArr.map((item, i) => (
              <div className="mb-3" key={i}>
                <div className="row">
                  <div className="col-sm-3 ">
                    <label htmlFor="cc-name">Select Variation</label>
                    <Select
                      options={attributesArr}
                      onChange={(e) => {
                        let arr = [...variationsArr];
                        arr[i].opt = e;
                        setVariationsArr(arr);
                      }}
                      value={item?.opt}
                    />
                  </div>
                  <div className="col-sm-3 ">
                    <label htmlFor="cc-name">Regular Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={item.regular_price}
                      onChange={(e) => {
                        let arr = [...variationsArr];
                        arr[i].regular_price = e.target.value;
                        setVariationsArr(arr);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 ">
                    <label htmlFor="cc-name">Sale Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={item.sale_price}
                      onChange={(e) => {
                        let arr = [...variationsArr];
                        arr[i].sale_price = e.target.value;
                        setVariationsArr(arr);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="cc-name">Action</label>
                    <div className="d-flex">
                      <button
                        className="btn btn-sm  btn-outline-danger rounded-pill"
                        onClick={() => {
                          setIsLoading(true);
                          axios
                            .delete(
                              `${siteUrl}products/${editObj?.id}/variations/${item.varId}?${ckcs}&api_key=${apiKey}`,
                              {
                                force: true,
                              }
                            )
                            .then((res) => {
                              setIsLoading(false);
                              Swal.fire({
                                icon: "success",
                                text: "Product variation deleted successFully",
                                allowOutsideClick: false,
                              }).then((resp) => {
                                if (resp.isConfirmed) {
                                  getProductVariations();
                                }
                              });
                            })
                            .catch((err) => {
                              setIsLoading(false);
                              Swal.fire({
                                text: `${err.response.message} || ${err.data.response.message} || 'Something went wrong while deleting Variations'`,
                              });
                            });
                        }}
                      >
                        <FaTrashAlt />
                        Remove
                      </button>
                      {editProduct && (
                        <button
                          className="btn btn-sm ml-2 btn-outline-info rounded-pill"
                          onClick={async () => {
                            setIsLoading(true);
                            const data = {
                              regular_price: item.regular_price,
                              sale_price: item?.sale_price,
                              attributes: [
                                { option: item.opt.value, name: attrName },
                              ],
                            };
                            await axios
                              .put(
                                `${siteUrl}products/${editObj?.id}/variations/${item.varId}?${ckcs}&api_key=${apiKey}`,
                                data
                              )
                              .then((res) => {
                                setIsLoading(false);
                                Swal.fire({
                                  icon: "success",
                                  allowOutsideClick: false,
                                  text: "Variation got updated",
                                });
                              })
                              .catch((err) => {
                                setIsLoading(false);
                                Swal.fire({
                                  icon: "error",
                                  allowOutsideClick: false,
                                  text:
                                    err?.response?.message ||
                                    err.response.data.message,
                                });
                              });
                          }}
                        >
                          <FaEdit />
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {varArr.map((item, i) => (
            <div className="mb-3" key={i}>
              <div className="row">
                <div className="col-sm-3 ">
                  <label htmlFor="cc-name">Select Variation</label>
                  <Select
                    options={attributesArr}
                    onChange={(e) => {
                      let arr = [...varArr];
                      arr[i].opt = e;
                      setVarArr(arr);
                    }}
                    value={item?.opt}
                  />
                </div>
                <div className="col-sm-3 ">
                  <label htmlFor="cc-name">Regular Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={item?.regular_price}
                    onChange={(e) => {
                      let arr = [...varArr];
                      arr[i].regular_price = e.target.value;
                      setVarArr(arr);
                    }}
                  />
                </div>
                <div className="col-sm-3 ">
                  <label htmlFor="cc-name">Sale Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={item?.sale_price}
                    onChange={(e) => {
                      let arr = [...varArr];
                      arr[i].sale_price = e.target.value;
                      setVarArr(arr);
                    }}
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="cc-name">Action</label>
                  <div className="d-flex">
                    <button
                      className="btn btn-sm  btn-outline-danger rounded-pill"
                      onClick={() => {
                        let arr = [...varArr];
                        let a = arr.filter((a) => a.id !== item.id);
                        setVarArr(a);
                      }}
                    >
                      <FaTrashAlt />
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="mb-1">
            {varArr && varArr.length !== 0 && (
              <button
                class="btn btn-primary rounded-pill"
                type="submit"
                onClick={handleSaveVariations}
              >
                Save
              </button>
            )}
          </div>{" "}
        </>
      ) : (
        ""
      )}
      <div>
        <h4>Product Title</h4>
        <div className="row">
          <div className="col-sm-6 mb-6">
            <label htmlFor="cc-name">Malayalam Title</label>
            <input
              className="form-control"
              placeholder="Product Name"
              type="text"
              value={skuData?.mal_title}
              onChange={(e) => {
                let data = { ...skuData, mal_title: e.target.value };
                setSkuData(data);
              }}
            />
          </div>
          <div className="col-sm-6 mb-3">
            <label htmlFor="cc-name">Purchase Title</label>
            <input
              className="form-control"
              type="text"
              placeholder="Regular Price"
              value={skuData?.purch_title}
              onChange={(e) => {
                let data = { ...skuData, purch_title: e.target.value };
                setSkuData(data);
              }}
            />
          </div>
          <div className="col-sm-6 mb-3">
            <label htmlFor="cc-name">English Title</label>
            <input
              className="form-control"
              type="text"
              placeholder="Regular Price"
              value={skuData?.eng_title}
              onChange={(e) => {
                let data = { ...skuData, eng_title: e.target.value };
                setSkuData(data);
              }}
            />
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <button
            class="btn btn-outline-primary mb-3 rounded-pill"
            type="submit"
            onClick={() => {
              if (skuData?.sku) {
                setSkuData(originalSkuData);
              } else {
                setSkuData(initialData);
              }
            }}
          >
            cancel
          </button>
          <div className="col-sm-2 mb-3">
            <button
              class="btn btn-primary rounded-pill"
              type="submit"
              onClick={updateSkuData}
            >
              {skuData?.sku != "" ? "Update Title" : "Add Title"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
