import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { updateOrder } from "../actions/Orders";

const OrderStatus = ({
  status,
  order,
  isLoading,
  setIsLoading,
  refreshItems,
  setRefreshItems,
}) => {
  const [open, setOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);

  useEffect(() => {
    statuses.map((item) => {
      if (item.value == status) {
        setStatusFilter(item);
      }
    });
  }, [status]);
  const statuses = [
    { value: "processing", label: "Processing" },
    { value: "cancelled", label: "Cancelled" },
    { value: "on-hold", label: "On Hold" },
    { value: "trash", label: "Trash" },
    { value: "assigned", label: "Assigned" },
    { value: "packing", label: "Packing" },
    { value: "customer-cancel", label: "Customer Cancel" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "delivered", label: "Delivered" },
    { value: "refunded", label: "Refunded" },
    { value: "outfordelivery", label: "Out for Delivery" },
  ];
  const handleFilterSelectedType = (type) => {
    setStatusFilter(type);
    const data = {
      status: type.value,
    };
    updateOrder(
      order.id,
      data,
      refreshItems,
      setRefreshItems,
      setIsLoading,
      setOpen
    );
  };
  switch (status) {
    case "processing":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="processing-btn">
                processing{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "cancelled":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="cancelled-btn">
                Cancelled{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "outfordelivery":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="cancelled-btn">
                Out for Delivery{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "on-hold":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="onhold-btn">
                On hold{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "assigned":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="assigned-btn">
                Assigned{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "trash":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="assigned-btn">
                Trash{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "packing":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="packing-btn">
                Packing{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "customer-cancel":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="cancelled-btn">
                Cancelled By Customer{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "refunded":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="cancelled-btn">
                Refunded{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "pending":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="cancelled-btn">
                Pending Payment{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "completed":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="completed-btn">
                Completed{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    case "delivered":
      return (
        <>
          {open ? (
            <div className="d-flex places">
              <Select
                options={statuses}
                placeholder="Select status"
                className="abc"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <FaTimes
                title="Cancel"
                style={{
                  cursor: "pointer",
                  color: "#000",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            <div className="statusButton">
              <p className="delivered-btn">
                Delivered{" "}
                <FaEdit
                  title="Edit"
                  style={{
                    cursor: "pointer",
                    color: "#000",
                  }}
                  onClick={() => setOpen(!open)}
                />
              </p>
            </div>
          )}
        </>
      );
    default:
      return "";
  }
};

export default OrderStatus;
