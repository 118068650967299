import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions/Sidebar";
import projectContext from "../../context/App.Context";
import { AuthProvider } from "../../context/AuthContext";

function MobileSidebar(props) {
  const { sidebarStatus } = useContext(projectContext);
  const changeNaveItemActive = (active) => {
    props.changeSidebarInfo("navActiveItem", active);
  };

  return (
    <div className="sidebar" style={{minHeight: '0px', width: '230px', padding: '0px'}}>
      <ul className="nav">
        <li
          className={`nav-item${
            props.navActiveItem === "dashboard" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("dashboard")}
        >
          <Link className="nav-link" to="/dashboard">
            <i className="icon-grid menu-icon" />
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "products" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("products")}
        >
          <Link className="nav-link" to="/products">
            <i className="mdi mdi-menu" />
            <span className="menu-title">Products</span>
          </Link>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "orders" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("orders")}
        >
          <Link className="nav-link" to="/orders">
            <i className="mdi mdi-shopping" />
            <span className="menu-title">Orders</span>
          </Link>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "calllogs" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("calllogs")}
        >
          <Link className="nav-link" to="/calllogs">
            <i className="mdi mdi-phone" />
            <span className="menu-title">Call Logs</span>
          </Link>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "payments" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("payments")}
        >
          <Link className="nav-link" to="/payments">
            <i className="mdi mdi-cash-multiple" />
            <span className="menu-title">Payments</span>
          </Link>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "payumoney" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("payumoney")}
        >
          <a
            className="nav-link"
            href="https://www.payu.in/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="mdi mdi-credit-card" />
            <span className="menu-title">PayUmoney</span>
          </a>
        </li>
        <li
          className={`nav-item${
            props.navActiveItem === "razorpay" ? " active" : ""
          }`}
          onClick={(e) => changeNaveItemActive("razorpay")}
        >
          <a
            className="nav-link"
            href="https://razorpay.com/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="mdi mdi-contactless-payment" />
            <span className="menu-title">Razorpay</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    navActiveItem: state.Sidebar.navActiveItem,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSidebarInfo: (f, e) => dispatch(actions.changeSidebarInfo(f, e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSidebar);
