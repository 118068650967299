import React, { useState } from "react";
import Routes from "./routes";
import "./App.css";
// Import root component
import { Provider } from "react-redux";
import { store } from "./stores/configureStore";
import { AuthProvider } from "./context/AuthContext";
import projectContext from "./context/App.Context";
import { ToastContainer } from "react-toastify";

const BaseApp = () => {
  const [sidebarStatus, setSidebarStatus] = useState(true);
  return (
    <Provider store={store}>
      <AuthProvider>
        <projectContext.Provider value={{sidebarStatus, setSidebarStatus}}>
          <div className="App">
            <ToastContainer />
            <Routes />
          </div>
        </projectContext.Provider>
      </AuthProvider>
    </Provider>
  );
};

BaseApp.displayName = "BaseApp";
export default BaseApp;
