/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import "../../App.css";
import { FaTimes } from "react-icons/fa";
import Loader from "../../common/loader/loader";

class AddHoliday extends Component {
  constructor(props) {
    super(props);
    let editpayment = props.editpayment;
    let isEdit = props.isEdit;
    this.state = {
      isEdit: props.isEdit,
      editpayment: props.editpayment,
      paymentId:
        isEdit && editpayment.order_number ? editpayment.order_number : null,
      order_date:
        isEdit && editpayment.order_date ? editpayment.order_date : null,
      delivery_date:
        isEdit && editpayment.delivery_date ? editpayment.delivery_date : null,
      customer: isEdit && editpayment.customer ? editpayment.customer : null,
      order_total:
        isEdit && editpayment.order_total ? editpayment.order_total : null,
      payment_method:
        isEdit && editpayment.payment_method
          ? this.renderMethod(editpayment.payment_method)
          : null,
      payment_status:
        isEdit && editpayment.payment_status
          ? this.renderStatus(editpayment.payment_status)
          : null,
      isOpen: false,
      progress: false,
      submitObj: {},
      isLoading: false,
      labelAddEdit: isEdit ? "Edit Holiday" : "Add Holiday",
      formErrors: {
        paymentId: "",
        customer: "",
        order_total: "",
        payment_method: "",
        payment_status: "",
        order_date: "",
        delivery_date: "",
      },
    };
  }

  handleClose = (e) => {
    this.state.isOpen = true;
    this.props.handleClose(this.state.isOpen);
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className="modal-wrapper">
        <div className="wrapper">
          <div className="form-wrapper">
            {this.state.isLoading ? <Loader /> : ""}

            <div class="modal-header">
              <div class="modal-title title-card">
                {this.state.labelAddEdit}
              </div>
              <FaTimes
                title="Close"
                style={{ cursor: "pointer" }}
                onClick={this.handleClose}
              />
            </div>
            <div class="modal-body">
              <form onSubmit={this.handleSubmit} noValidate>
                <label class="col-sm-4 col-form-label">Holiday Name</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Enter Holiday Name"
                    type="text"
                    name="holidayName"
                    value={this.state.paymentId}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.paymentId.length > 0 && (
                    <span className="errorMessage">{formErrors.paymentId}</span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Holiday Date</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Enter holiday date"
                    type="date"
                    name="holidayDate"
                    value={this.state.paymentId}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.paymentId.length > 0 && (
                    <span className="errorMessage">{formErrors.paymentId}</span>
                  )}
                </div>

                <div class="modal-footer col-sm-12">
                  <button class="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddHoliday;
