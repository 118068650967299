import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getDeliveryDate, updateOrder } from "../actions/Orders";

const ViewOrder = ({
  setIsViewOrder,
  order,
  refreshItems,
  setRefreshItems,
  setLoading,
}) => {
  const [statusData, setStatusData] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [newDetails, setNewDetails] = useState(null);
  const [deliveryStaffName, setDeliveryStaffName] = useState('')
  const [Ffname, setFfname] = useState('')

  const renderStatus = (status) => {
    switch (status) {
      case "processing":
        return (
          <div style={{ display: "block" }}>
            <div className="statusButton processing-btn">processing</div>
          </div>
        );
      case "cancelled":
        return <div className="statusButton cancelled-btn">Cancelled</div>;
      case "on-hold":
        return <div className="statusButton onhold-btn">On hold</div>;
      case "assigned":
        return <div className="statusButton assigned-btn">Assigned</div>;
      case "trash":
        return (
          <div className="statusButton">
            <p className="assigned-btn">Trash</p>
          </div>
        );
      case "packing":
        return <div className="statusButton packing-btn">Packing</div>;
      case "customer-cancel":
        return (
          <div className="statusButton cancelled-btn">
            Cancelled By Customer
          </div>
        );
      case "pending":
        return (
          <div className="statusButton cancelled-btn">Pending Payment</div>
        );
      case "completed":
        return <div className="statusButton completed-btn">Completed</div>;
      case "delivered":
        return <div className="statusButton delivered-btn">Delivered</div>;
      case "outfordelivery":
        return (
          <div className="statusButton cancelled-btn">Out for Delivery</div>
        );
      case "refunded":
        return <div className="statusButton cancelled-btn">Refunded</div>;
      default:
        return "";
    }
  };
  const statuses = [
    { value: "processing", label: "Processing" },
    { value: "cancelled", label: "Cancelled" },
    { value: "on-hold", label: "On Hold" },
    { value: "trash", label: "Trash" },
    { value: "assigned", label: "Assigned" },
    { value: "packing", label: "Packing" },
    { value: "customer-cancel", label: "Customer Cancel" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "delivered", label: "Delivered" },
    { value: "refunded", label: "Refunded" },
    { value: "outfordelivery", label: "Out for Delivery" },
  ];
  useEffect(() => {
    statuses.map((item) => {
      if (item.value == order.status) {
        setStatusFilter(item);
      }
    });
  }, [order]);
  const handleFilterSelectedType = (type) => {
    setStatusFilter(type);
    const data = {
      status: type.value,
    };
    setStatusData(data);
  };
  const changeStatusOfOrder = () => {
    updateOrder(
      order.id,
      statusData,
      refreshItems,
      setRefreshItems,
      setLoading,
      setIsViewOrder
    );
  };
  useEffect(() => {
    getDeliveryDate(order.id, setLoading, setNewDetails);
  }, []);
  var subTotal = order?.line_items?.reduce(
    (accum, item) => accum + Number(item.subtotal),
    0
  );
  useEffect(()=>{
    order?.meta_data?.map((item) => {
      if(item.key == '_wc_acof_3'){
        setDeliveryStaffName(item.value)
    } })
    order?.meta_data?.map((item) => {
      if(item.key == '_wc_acof_2'){
        setFfname(item.value)
    } })
  },[order])
  return (
    <div className="card">
      <div className="card-body">
        <h4
          className="card-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="viewOrderHeader">
            <div>Order #{order?.id}</div>
            <div
              className="col-sm-3"
              style={{ borderRight: "2px solid #C8D7E1" }}
            >
              {renderStatus(order?.status)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <FaTimes
              onClick={() => {
                setIsViewOrder(false);
              }}
              style={{ cursor: "pointer", float: "right" }}
            />
          </div>
        </h4>
        <hr />
        <div>
          <Row style={{ marginRight: "0px" }}>
            <Col xs={4}>
              <div className="billingaddress">
                <h5 className="billingHeaderText">Billing details</h5>
                <div className="billing_details">
                  <p className="billing-text">
                    {order?.billing?.first_name} {order?.billing?.last_name}
                  </p>
                  <p className="billing-text">{order?.billing?.address_1}</p>
                  <p className="billing-text">{order?.billing?.address_2}</p>
                  <p className="billing-text">
                    {order?.billing?.city} {order?.billing?.postcode}
                  </p>
                  <p className="billing-text">{order?.billing?.state}</p>
                </div>
                <div className="billing-sub-header">
                  <div className="billing-sub-header-text">Email</div>
                  <a
                    href={`mailto:${order?.billing?.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="billing-text">{order?.billing?.email}</p>
                  </a>
                </div>
                <div className="billing-sub-header">
                  <div className="billing-sub-header-text">Phone</div>
                  <p className="billing-text">{order?.billing?.phone}</p>
                </div>
                <div className="billing-sub-header">
                  <div className="billing-sub-header-text">Payment Via</div>
                  <p className="billing-text">{order?.payment_method_title}</p>
                  {order?.transaction_id ? (
                    <p>({order?.transaction_id})</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col xs={3}>
              {order.shipping ? (
                <>
                  <div className="billingaddress">
                    <h5 className="billingHeaderText">Shipping details</h5>
                    <p>
                      <a
                        href={`https://maps.google.com/maps?&q=${order?.shipping?.address_1}%2C${order?.shipping?.address_2}%2C${order?.shipping?.city}%2C${order?.shipping?.state}%2C${order?.shipping?.postcode}%2C%2C${order?.shipping?.country}&z=16`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {order?.shipping?.first_name}{" "}
                        {order?.shipping?.last_name}
                        {order?.shipping?.address_1}
                        {order?.shipping?.address_2}
                        {order?.shipping?.city} {order?.shipping?.postcode}
                        {order?.shipping?.state}
                      </a>{" "}
                    </p>
                  </div>
                  <div className="billing-sub-header">
                    <div className="billing-sub-header-text">
                      Shipping Method
                    </div>
                    <p className="billing-text">
                      {order?.shipping_lines[0]?.method_title}
                    </p>
                  </div>
                  {newDetails?.order["Delivery Date"] ? (
                    <div
                      className="billing-sub-header"
                      style={{ color: "#006400" }}
                    >
                      <div className="billing-sub-header-text">
                        Delivery Date
                      </div>
                      <p className="billing-text">
                        {newDetails?.order["Delivery Date"]}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {Ffname !== "" ? (
                    <div className="billing-sub-header">
                      <div className="billing-sub-header-text">
                        Fulfillment Center
                      </div>
                      <p
                        className="billing-sub-header-text"
                        style={{ color: "#006400" }}
                      >
                        {Ffname?.toUpperCase()}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {deliveryStaffName !== "" ? (
                    <div className="billing-sub-header">
                      <div className="billing-sub-header-text">
                        Delivery Staff
                      </div>
                      <p className="billing-sub-header-text" style={{ color: "#006400" }}>
                        {deliveryStaffName?.toUpperCase()}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Col>
            <Col xs={3}>
              <div className="billingaddress">
                <h5 className="billingHeaderText">Order Summary</h5>
                <div>
                  <div className="row priceTotal">
                    <p>Items Subtotal: </p>
                    <p className="fw-500">
                      <b>Rs {subTotal}</b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>Shipping: </p>
                    <p className="fw-500">
                      <b>Rs {order?.shipping_total}</b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>CGST: </p>
                    <p className="fw-500">
                      <b>
                        Rs{" "}
                        {order?.line_items[0]?.taxes[0]?.subtotal &&
                          parseFloat(
                            order?.line_items[0]?.taxes[0]?.subtotal
                          ).toFixed(2)}
                      </b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>SGST: </p>
                    <p className="fw-500">
                      <b>
                        Rs{" "}
                        {order?.line_items[0]?.taxes[1]?.subtotal &&
                          parseFloat(
                            order?.line_items[0]?.taxes[1]?.subtotal
                          ).toFixed(2)}
                      </b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>CESS: </p>
                    <p className="fw-500">
                      <b>
                        Rs{" "}
                        {order?.line_items[0]?.taxes[2]?.subtotal &&
                          parseFloat(
                            order?.line_items[0]?.taxes[2]?.subtotal
                          ).toFixed(2)}
                      </b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>Order Total: </p>
                    <p className="fw-500">
                      <b>Rs {order?.total}</b>
                    </p>
                  </div>
                  <div className="row priceTotal">
                    <p>Cost of Goods: </p>
                    <p className="fw-500">
                      <b>Rs 0.00</b>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginRight: "0px" }}>
            <Col xs={12}>
              <div className="table-responsive" style={{ width: "80%" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Tax</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.line_items?.length !== 0
                      ? order?.line_items.map((item) => {
                          return (
                            <tr>
                              <td>
                                {item.name} {item.sku}
                              </td>
                              <td>{item.quantity}</td>
                              <td>{item.subtotal_tax}</td>
                              <td>{item.subtotal}</td>
                            </tr>
                          );
                        })
                      : "No Items attached for this order"}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col xs={9}>
            <div className="d-flex">
              <Select
                options={statuses}
                placeholder="Select status"
                className="selectContainer"
                name="status"
                value={statusFilter}
                onChange={handleFilterSelectedType}
              />
              <Button className="ml-3" size="sm" onClick={changeStatusOfOrder}>
                Change Status
              </Button>
            </div>
          </Col>
          <Col xs={3}>
            <Link to={`/orders/${order.id}`}>
              <Button size="sm">Edit</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewOrder;
