import React, { useState, useEffect } from "react";
import ToggleSwitch from "../common/toggle/Toggle";
import _ from "lodash";
import axios from "axios";
import Loader from "../common/loader/loader";

const DeliveryPanel = () => {
  const [value, setValue] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [deliverydays, setDeliverydays] = useState({});
  const [deliverysettings, setDeliverysettings] = useState({});
  const deliveryDays = [
    {
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    },
  ];
  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    setIsLoading(true);
    axios
      .get(
        "https://firestore.googleapis.com/v1/projects/my-online-vipani-react-native/databases/(default)/documents/order_delivery_date"
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.documents;
        if (data.length > 0) {
          data.map((day) => {
            if (
              day.name ===
              "projects/my-online-vipani-react-native/databases/(default)/documents/order_delivery_date/delivery_days"
            ) {
              setDeliverydays(day?.fields);
            } else if (
              day.name ===
              "projects/my-online-vipani-react-native/databases/(default)/documents/order_delivery_date/ delivery_settings"
            ) {
              setDeliverysettings(day?.fields);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  console.log(deliverydays);
  console.log(deliverysettings);
  return (
    <div className="card">
      {isLoading ? <Loader /> : ""}
      <div className="card-body">
        <div className="delivery-panel">
          <div className="delivery-days">
            <h4>Delivery days</h4>
            <div className="pt-3">
              {deliverydays?.sunday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Sunday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.sunday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.monday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Monday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.monday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.tuesday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Tuesday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.tuesday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.wednesday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Wednesday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.wednesday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.thursday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Thursday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.thursday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.friday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Friday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.friday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverydays?.saturday ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3" style={{ width: "100px" }}>
                    Saturday
                  </h6>
                  <ToggleSwitch
                    isOn={deliverydays?.saturday?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="delivery-settings">
            <h4>Delivery Settings</h4>
            <div className="pt-3">
              {deliverysettings?.is_mandatory ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3">Is Mandatory:</h6>
                  <ToggleSwitch
                    isOn={deliverysettings?.is_mandatory?.booleanValue}
                    onColor="green"
                  />
                </div>
              ) : (
                ""
              )}
              {deliverysettings?.no_of_days_to_choose ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3">Number of days to Choose:</h6>
                  <p>
                    <b>
                      {deliverysettings?.no_of_days_to_choose?.integerValue}
                    </b>
                  </p>
                </div>
              ) : (
                ""
              )}
              {deliverysettings?.cutt_off_time ? (
                <div
                  className="makeDefault"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6 className="pr-3">Cut off Time:</h6>
                  <p>
                    <b>{deliverysettings?.cutt_off_time?.stringValue}</b>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPanel;
