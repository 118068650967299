import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { ckcs, siteUrl } from "../actions/common/ApiLinks";

const EditOrderRow = ({
  item,
  index,
  setIsEditOrder,
  cancelEdit,
  setCancelEdit,
  productsRelOrder,
  setProductRelOrder,
  refreshItems,
  setRefreshItems,
  OrderId,
  currentOrderDetails,
  setCurrentOrderDetails,
  setIsLoading,
  isRefund,
  refundItems,
  setRefundItems
}) => {
  const [displayEditIcon, setDisplayEditIcon] = useState(true);
  const [editTrue, setEditTrue] = useState(false);
  const [refundQuantity, setRefundQuantity] = useState(null);
  useEffect(() => {
    if (cancelEdit) setDisplayEditIcon(cancelEdit);
  }, [cancelEdit]);
  const handleDeleteProductFromOrder = (Id, setIsLoading) => {
    setIsLoading(true);
    let d = [...productsRelOrder];
    let matchRecord = d.filter((item) => item.id == Id);
    if (matchRecord?.length !== 0) {
      let data = {
        id: OrderId,
        line_items: [{ id: matchRecord[0].id, quantity: 0 }],
      };
      axios
        .put(`${siteUrl}orders/${OrderId}?${ckcs}`, data)
        .then((res) => {
          setIsLoading(false);
          Swal.fire({
            text: "product removed successfully from the order",
            icon: "success",
            allowOutsideClick:false,
          }).then((res) => {
            if (res.isConfirmed) {
              setRefreshItems(!refreshItems);
            }
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };
  return (
    <tr>
      <td>
        <img src={item.ams_order_thumbnail} alt="" />
        {item.name}
      </td>
      <td>-</td>
      <td>{item?.price && parseFloat(item?.price).toFixed(2)}</td>
      {editTrue ? (
        <td>
          <input
            type="number"
            value={item.quantity}
            style={{ width: "150%" }}
            onKeyDown={formatInput}
            onChange={(e) => {
              let a = [...productsRelOrder];
              a[index].quantity = Number(e.target.value);
              setProductRelOrder(a);
            }}
            onBlur={() => {
              setDisplayEditIcon(true);
            }}
          />
        </td>
      ) : (
        <td style={{ display: "flex", flexDirection: "column" }}>
          * {item.quantity}{" "}
          {isRefund ? (
            <input
              type="number"
              onKeyDown={formatInput}
              style={{ width: "150%" }}
              value={refundItems[index]?.quantity}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= item.quantity &&
                  Number(e.target.value) >= 0
                ) {
                  let a = [...refundItems];
                  a[index].quantity = Number(e.target.value);
                  a[index].refund_total = (Number(e.target.value) * Number(item.price)).toFixed(2)
                  setRefundItems(a);
                } else {
                  let a = [...refundItems];
                  a[index].quantity = refundItems[index].quantity;
                  setRefundQuantity(a);
                }
              }}
            />
          ) : (
            ""
          )}
        </td>
      )}
      <td>{item.subtotal}</td>
      <td>
        {item?.taxes[0]?.subtotal &&
          parseFloat(item?.taxes[0]?.subtotal).toFixed(2)}
      </td>
      <td>
        {item?.taxes[1]?.subtotal &&
          parseFloat(item?.taxes[1]?.subtotal).toFixed(2)}
      </td>
      <td>
        {item?.taxes[2]?.subtotal &&
          parseFloat(item?.taxes[2]?.subtotal).toFixed(2)}
      </td>
      {currentOrderDetails?.status == "on-hold" ? (
        <td>
          {displayEditIcon ? (
            <FaEdit
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsEditOrder(true);
                setEditTrue(true);
                setDisplayEditIcon(!displayEditIcon);
                setCancelEdit(false);
              }}
            />
          ) : (
            // <FaTimes
            //   onClick={() => {
            //     setCurrentOrderDetails({...currentOrderDetails});
            //     setEditTrue(false);
            //     setDisplayEditIcon(!displayEditIcon);
            //   }}
            //   style={{ cursor: "pointer" }}
            // />
            ""
          )}
          &nbsp;&nbsp;&nbsp;
          <FaTrashAlt
            style={{ cursor: "pointer" }}
            title="Delete"
            onClick={() =>
              Swal.fire({
                title: "Do you want to Delete the Call log?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Delete`,
                denyButtonText: `Cancel`,
                confirmButtonColor: "#39f",
                icon: "info",
                allowOutsideClick:false,
              }).then((response) => {
                if (response.isConfirmed) {
                  handleDeleteProductFromOrder(item.id, setIsLoading);
                }
              })
            }
          />
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

export default EditOrderRow;
