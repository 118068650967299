import React from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const HolidaysTable = ({togglePopup, holidaysList}) => {

  const renderHolidayName = (name) => {
    if(name?.length > 0){
    let n = /[^/]*$/.exec(`${name}`)[0];
    const holiday = n.charAt(0).toUpperCase() + n.slice(1);
    return holiday;
    }
  }

  const renderDate = (date) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let a = new Date(`${date}`);
    let b = a.toLocaleDateString("en-US", options)
    return b;
  }
  
  return (
    <div className="card">
      <div className="card-body">
        <h4
          className="card-title header-filter"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Holidays
          <FaPlus
            title="Add Holiday"
            // onClick={() => togglePopup()}
            style={{ float: "right", cursor: "pointer", marginRight: "5%" }}
            className="filterIcon"
          />
        </h4>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
              <th>Holiday</th>
                <th>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Holiday Date
                  </div>
                </th>
                
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {holidaysList && holidaysList.length > 0 ? holidaysList.map((holiday => 
                <tr>
                <td>{renderHolidayName(holiday.name)}</td>
                <td>{renderDate(holiday?.fields?.holiday_date?.stringValue)}</td>
              </tr>
              )): <tr><td className="makeDefault">No Holidays in the List</td><td></td></tr>
              }

              {/* <td data-label="Actions">
                  &nbsp;&nbsp;&nbsp;
                  <FaEdit title="Edit" style={{ cursor: "pointer" }} />
                  &nbsp;&nbsp;&nbsp;
                  <FaTrashAlt
                    style={{ cursor: "pointer" }}
                    title="Delete"
                    onClick={() =>
                      Swal.fire({
                        text: "Are you sure about to delete this Holiday?",
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Delete`,
                        denyButtonText: `Cancel`,
                        confirmButtonColor: "#39f",
                        icon: "info",
                      }).then((result) => {
                        if (result.isConfirmed) {
                        }
                      })
                    }
                  />
                </td> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HolidaysTable;
