import React, { useEffect, useState } from "react";
import { getOrdersStatisticsByCenters } from "../actions/Orders";
import Loader from "../common/loader/loader";

const OrderDashboard = () => {
  const [orderStats, setOrderStats] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getOrdersStatisticsByCenters(setLoading, setOrderStats);
  }, []);
  return (
    <div>
      {loading ? <Loader /> : ""}
      <h4
        className="card-title pb-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          fontSize: '25px',
          fontWeight: '600'
        }}
      >
        Order(s) Summary
      </h4>
      <div>
        {orderStats?.length !== 0 ? (
          orderStats.map((orderStat, index) => {
            if (orderStat.center == "Top Section") {
              return (
                <div className="row p-1" key={index}>
                  <div className="col-sm-2 places mb-2">
                    <div className=""></div>
                  </div>
                  <div className="col-sm-3 unassignedbox ml-2 mb-1">
                    <h4>Unassigned</h4>
                    <div className="roundNumber p-2">
                      {orderStat.unassigned}
                    </div>
                  </div>
                  <div className="col-sm-3 todayorderbox ml-2 mb-1">
                    <h4>Today's Delivery</h4>
                    <div className="roundNumber p-2">
                      {orderStat.todays_delivery}
                    </div>
                  </div>
                  <div className="col-sm-3 futureorderbox ml-2 mb-1">
                    <h4>Future Orders</h4>
                    <div className="roundNumber p-2">
                      {orderStat.future_orders}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="row p-1" key={index}>
                  <div className="col-sm-2 places mb-2">
                    <div className="placeDiv">
                      {orderStat?.center?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-sm-3 assignedbox ml-2 mb-1">
                    <h4>Assigned</h4>
                    <div className="roundNumber p-2">{orderStat.assigned}</div>
                  </div>
                  <div className="col-sm-3 packingbox ml-2 mb-1">
                    <h4>Packing</h4>
                    <div className="roundNumber p-2">{orderStat.packing}</div>
                  </div>
                  <div className="col-sm-3 deliverybox ml-2 mb-1">
                    <h4>Out for Delivery</h4>
                    <div className="roundNumber p-2">
                      {orderStat.out_for_delivery}
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className="loadingContent">{loading ? "Loading...." : ""}</div>
        )}
      </div>
    </div>
  );
};

export default OrderDashboard;
