/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './loader.css';

export default function Loader() {
  const [loaderINFO, setLoaderINFO] = useState('Please Wait...');
  return (
    <div className="loaderContianer">
      <div className="overlayLoader"></div>
      <div className="loaderContent">
        <div className="loader"></div>
        <div className="loaderInfo">{loaderINFO}</div>
      </div>
    </div>
  );
}
