import React, { Component, useEffect } from "react";
import "../../App.css";
import { FaTimes } from 'react-icons/fa';
import { getUsers, moviDb, auth, } from "../../firebase/index";
import Progress from "./Progress";
import Select from 'react-select'
import firebase from 'firebase';
import moment from 'moment';
import Loader from "../../common/loader/loader";
import Swal from "sweetalert2";





const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};

const options = [
  { value: 'General Enquiry', label: 'General Enquiry' },
  { value: 'Order', label: 'Order' },
  { value: 'New Product Request', label: 'New Product Request' },
  { value: 'Website', label: 'Website' },
  { value: 'Mobile App', label: 'Mobile App' },
  { value: 'Payment', label: 'Payment' },
  { value: 'Business', label: 'Business' },
  { value: 'Other', label: 'Other' }
]


class Popup extends Component {
  constructor(props) {
    super(props);
    let editcallLogObj = props.editcallLogObj;
    let isEdit = props.isEdit;
    this.state = {
      isEdit: props.isEdit,
      editcallLogObj: props.editcallLogObj,
      callType:
        isEdit && editcallLogObj.call_type ? this.renderCallType(editcallLogObj.call_type) : null,
      message:
        isEdit && editcallLogObj.call_message
          ? editcallLogObj.call_message
          : null,
      orderNumber:
        isEdit && editcallLogObj.order_number
          ? editcallLogObj.order_number
          : null,
      phoneNumber: isEdit && editcallLogObj.phone ? editcallLogObj.phone : null,
      customerName:
        isEdit && editcallLogObj.customer ? editcallLogObj.customer : null,
      checked:
        isEdit && editcallLogObj.follow_up ? editcallLogObj.follow_up : false,
      isOpen: false,
      progress: false,
      submitObj: {},
      labelAddEdit: isEdit ? "Edit Call Logs" : "Add Call Logs",
      formErrors: {
        callType: "",
        message: "",
        orderNumber: "",
        phoneNumber: "",
        customerName: "",
      },
    };
  }

  handleClose = (e) => {
    this.state.isOpen = true;
    this.props.handleClose(this.state.isOpen);
  };

  handleFollowup = (e) => {
    this.state.checked = !this.state.checked;
  };
  renderCallType = (value) => {
    switch (value) {
      case "General Enquiry":
        return { value: 'General Enquiry', label: 'General Enquiry' };
      case "Order":
        return { value: 'Order', label: 'Order' };
        case "New Product Request":
        return { value: 'New Product Request', label: 'New Product Request' };
        case "Website":
        return { value: 'Website', label: 'Website' };
        case "Mobile App":
        return { value: 'Mobile App', label: 'Mobile App' };
        case "Payment":
        return { value: 'Payment', label: 'Payment' };
        case "Business":
        return { value: 'Business', label: 'Business' };
        case "Other":
          return  { value: 'Other', label: 'Other' };
      default:
        return "";
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
      this.state.submitObj = {
        phone: this.state.phoneNumber,
        call_message: this.state.message,
        call_type: this.state.callType.value,
        customer: this.state.customerName,
        order_number: this.state.orderNumber,
        call_date_time: moment().format("YYYY-MM-DD"),
        follow_up: this.state.checked,
      };
      if (!this.state.isEdit) {
        this.props.setProgress(true)
        moviDb
          .collection("call_log")
          .add(this.state.submitObj)
          .then((doc) => {
            const responseTodoItem = this.state.submitObj;
            responseTodoItem.id = doc.id;
            this.props.handleCallLogChange(responseTodoItem);
            this.props.setProgress(false)
            Swal.fire({
              text: "Call Log created successfully",
              icon: "success",
            }).then((res) => {
              if (res.isConfirmed) {
                this.props.getCallLogs();
              }
            });
          })
          .catch((err) => {
            this.props.setProgress(false)
            Swal.fire({
              text: "Failed to Update",
              icon: "error",
            });
          });
        this.handleClose();
      } else {
        this.state.submitObj.id = this.state.editcallLogObj.id;
        this.props.setProgress(true)
        const document = moviDb
          .collection("call_log")
          .doc(this.state.editcallLogObj.id)
          .update(this.state.submitObj)
          .then((doc) => {
            const responseTodoItem = this.state.submitObj;
            this.props.handleCallLogChange(responseTodoItem);
            this.props.setProgress(false);
            Swal.fire({
              text: "Updated successfully",
              icon: "success",
            }).then((res) => {
              if (res.isConfirmed) {
                this.props.getCallLogs();
              }
            });
          })
          .catch((err) => {
            this.props.setProgress(false);
            Swal.fire({
              text: "Failed to create",
              icon: "error",
            });
          });
        this.handleClose();
      }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "callType":
        formErrors.callType =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "message":
        formErrors.message =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "customerName":
        formErrors.customerName =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "phoneNumber":
        formErrors.phoneNumber =
          value.length < 10 ? "minimum 10 characaters required" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };
  handleSelectcallType = (callType) => {
    this.setState({ callType: callType });
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className="modal-wrapper">
        <div className="wrapper">
          <div className="form-wrapper">
            {this.state.progress ? <Loader /> : ''}

            <div class="modal-header">
              <div class="modal-title title-card">
                {this.state.labelAddEdit}
              </div>
              <FaTimes onClick={this.handleClose} />
            </div>
            <div class="modal-body">
              <form onSubmit={this.handleSubmit} noValidate>
                <label class="col-sm-4 col-form-label">Call Type</label>
                <div class="col-sm-8">
                  <Select
                    options={options}
                    class="form-control form-control-sm"
                    placeholder="Call Type"
                    name="callType"
                    value={this.state.callType}
                    onChange={this.handleSelectcallType}
                  />
                  {/* <input
                  class="form-control form-control-sm"
                  placeholder="Call Type"
                  type="text"
                  name="callType"
                  value={this.state.callType}
                  noValidate
                  onChange={this.handleChange}
                /> */}

                  {formErrors.callType.length > 0 && (
                    <span className="errorMessage">{formErrors.callType}</span>
                  )}
                </div>

                <label class="col-sm-4 col-form-label">Call Message</label>
                <div class="col-sm-8 textarea-class">
                  <textarea
                    class="form-control form-control-sm"
                    placeholder="Call message details"
                    name="message"
                    value={this.state.message}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.message.length > 0 && (
                    <span className="errorMessage">{formErrors.message}</span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Customer Name</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Customer Name"
                    type="text"
                    name="customerName"
                    value={this.state.customerName}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.customerName.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.customerName}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Order Number</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Order Number"
                    type="number"
                    name="orderNumber"
                    value={this.state.orderNumber}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.orderNumber.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.orderNumber}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Phone Number</label>
                <div class="col-sm-8">
                  <input
                    class="form-control form-control-sm"
                    placeholder="Phone Number"
                    type="number"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    noValidate
                    onChange={this.handleChange}
                  />
                  {formErrors.phoneNumber.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.phoneNumber}
                    </span>
                  )}
                </div>
                <label class="col-sm-4 col-form-label">Follow Up</label>
                <div class="col-sm-8">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="btn-check-outlined"
                    defaultChecked={this.state.checked}
                    onClick={this.handleFollowup}
                  />
                  <label class="col-sm-4 col-form-label"></label>
                </div>
                <div class="modal-footer col-sm-12">
                  <button class="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;