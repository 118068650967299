import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import AsyncInputRow from "./AsyncRow";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { ckcs, siteUrl } from "../../actions/common/ApiLinks";

const AddNewProductToOrder = ({ setIsAddNewProduct, order, OrderId, setIsLoading, setRefreshItems,
  refreshItems }) => {
  const [newProducts, setNewProducts] = useState([]);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    let a = [...order?.line_items];
    let b = [];
    if (a.length !== 0) {
      a.forEach((item) => {
        let c = { id: item.id, quantity: item.quantity };
        b.push(c);
      });
      setLineItems(b);
    }
  }, [order]);
  useEffect(() => {
    let arr = [...lineItems];
    let a = [...newProducts];
    let b = [];
    if (a.length !== 0) {
      a.forEach((item) => {
        let c = { product_id: item.product_id, quantity: item.quantity };
        b.push(c);
      });
      let d = lineItems.concat(b);
      setLineItems(d);
    }
  }, [newProducts]);
  const SaveProducts = () => {
    let a = lineItems.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.product_id === thing.product_id && t.quantity === thing.quantity
        )
    );
    setIsLoading(true);
    let data = {
      id: OrderId,
      line_items: a,
    };
    axios
      .put(`${siteUrl}orders/${OrderId}?${ckcs}`, data)
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          text: "products added successfully to the order",
          icon: "success",
        }).then((res) => {
          if (res.isConfirmed) {
            setNewProducts([]);
            setLineItems([]);
            setIsAddNewProduct(false);
            setRefreshItems(!refreshItems);
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <div className="modal-wrapper">
      <div className="wrapper">
        <div className="form-wrapper">
          <div class="modal-header">
            <div class="modal-title title-card">Add New Products to order</div>
            <FaTimes
              onClick={() => setIsAddNewProduct(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div class="modal-body">
            <Row>
              <Col xs={6}>
                <h4>Product</h4>
              </Col>
              <Col xs={3}>
                <h4>Quantity</h4>
              </Col>
            </Row>
            {newProducts?.map((item, index) => {
              return (
                <Row key={index}>
                  <Col xs={6}>{item.name}</Col>
                  <Col xs={3}>{item.quantity}</Col>
                </Row>
              );
            })}
            <table>
              <tbody>
                <AsyncInputRow
                  setNewProducts={setNewProducts}
                  newProducts={newProducts}
                />
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <Button size="sm" onClick={SaveProducts}>Save Products</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProductToOrder;
