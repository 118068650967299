import axios from "axios";
import Swal from "sweetalert2";
import config, { apiKey, ckcs, devUrl, siteUrl } from "./common/ApiLinks";

export const changeProductInfo = (field, value) => {
  return {
    type: "UPDATE_PRODUCTS_VALUE",
    field,
    value,
  };
};

export const getClientList = (
  setProducts,
  setIsLoading,
  setFilteredProducts
) => {
  setIsLoading(true);
  fetch(`${config.product_list}`, {
    method: "GET",
  })
    .then((response) => {
      setIsLoading(false);
      if (response) {
        return response.json();
      }
    })
    .then((responseData) => {
      setIsLoading(false);
      if (responseData) {
        setProducts(responseData);
        setFilteredProducts(responseData);
      } else {
        setProducts([]);
        setFilteredProducts([]);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};
export const postProduct = (
  data,
  setIsLoading,
  setRefreshItems,
  refreshItems,
  setCreateProduct,
  productZipcodes,
  setEditObj,
  setEditProduct,
  setVarProData,
  setShowVari
) => {
  setIsLoading(true);
  axios
    .post(`${config.product_list}`, data)
    .then((response) => {
      setShowVari(true);
      setVarProData(response.data);
      let message = "";
      if (response.data.type == "variable") {
        message = "product Created, Please add variations below";
      } else {
        message = "product Created successfully";
      }
      postZipcodeGroup(
        response.data.id,
        productZipcodes,
        setIsLoading,
        setRefreshItems,
        refreshItems,
        setCreateProduct,
        setEditObj,
        message,
        setEditProduct,
        response
      );
    })
    .catch((err) => {
      setIsLoading(false);
      Swal.fire({
        text:
          err?.message || err.response?.message || err?.response?.data?.message,
        icon: "error",
        allowOutsideClick: false,
      });
    });
};
export const saveAtt = (
  data,
  setIsLoading,
  setRefreshItems,
  refreshItems,
  setCreateProduct,
  productZipcodes,
  setEditObj,
  setEditProduct
) => {
  setIsLoading(true);
  axios
    .post(`${siteUrl}products/attributes?${ckcs}&api_key=${apiKey}`, data)
    .then((response) => {
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      Swal.fire({
        text:
          err?.message || err.response?.message || err?.response?.data?.message,
        icon: "error",
        allowOutsideClick: false,
      });
    });
};
export const postVarProduct = (
  data,
  setIsLoading,
  setRefreshItems,
  refreshItems,
  setCreateProduct,
  productZipcodes,
  setEditObj,
  setEditProduct
) => {
  setIsLoading(true);
  axios
    .post(`${config.product_list}`, data)
    .then((response) => {
      postZipcodeGroup(
        response.data.id,
        productZipcodes,
        setIsLoading,
        setRefreshItems,
        refreshItems,
        setCreateProduct,
        setEditObj,
        "Created",
        setEditProduct
      );
    })
    .catch((err) => {
      setIsLoading(false);
      Swal.fire({
        text:
          err?.message || err.response?.message || err?.response?.data?.message,
        icon: "error",
        allowOutsideClick: false,
      });
    });
};
export const updateProduct = (
  id,
  data,
  setIsLoading,
  setRefreshItems,
  refreshItems,
  setCreateProduct,
  zipcodeGroup,
  setEditObj,
  setEditProduct,
  setVarProData,
  setShowVari
) => {
  const url = `${devUrl}/products/${id}?${ckcs}&api_key=${apiKey}`;
  setIsLoading(true);
  axios
    .put(url, data)
    .then((response) => {
      setShowVari(true);
      setVarProData(response.data);
      postZipcodeGroup(
        id,
        zipcodeGroup,
        setIsLoading,
        setRefreshItems,
        refreshItems,
        setCreateProduct,
        setEditObj,
        "Updated",
        setEditProduct,
        response
      );
    })
    .catch((err) => {
      setIsLoading(false);
    });
};

export const deleteProduct = (
  id,
  refreshItems,
  setRefreshItems,
  setIsLoading
) => {
  setIsLoading(true);
  const url = `${devUrl}/products/${id}?force=true&${ckcs}&api_key=${apiKey}`;
  axios
    .delete(url)
    .then((response) => {
      setIsLoading(false);
      Swal.fire({
        text: "Successfully Deleted",
        icon: "success",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          setRefreshItems(!refreshItems);
        }
      });
    })
    .catch((err) => setIsLoading(false));
};
export const postImage = (data, setIsLoading, setProductImageURL) => {
  const url = `${devUrl}/media?${ckcs}&api_key=${apiKey}`;
  const headers = {
    "Content-Type": "image/png",
  };
  setIsLoading(true);
  axios
    .post(url, data, { headers: headers })
    .then((response) => {
      setIsLoading(false);
      setProductImageURL(response.data);
      Swal.fire({
        text: "Image Uploaded",
        icon: "success",
        allowOutsideClick: false,
      });
    })
    .catch((err) => {
      setIsLoading(false);
    });
};
export const getZipcodes = (loading, setZipcodes) => {
  loading(true);
  let values = [];
  let url = `${devUrl}/zipcode_groups?api_key=${apiKey}&${ckcs}`;
  axios
    .get(url)
    .then((res) => {
      loading(false);
      let data = res.data;
      data.forEach((element) => {
        let a = { value: element.zone_id, label: element.zone_name };
        values.push(a);
      });
      setZipcodes(values);
    })
    .catch((err) => {
      loading(false);
      console.log(err);
    });
};
const setSkuInWordpress = (setIsLoading, productId) => {
  setIsLoading(true);
  axios
    .get(`${devUrl}/setpost?productID=${productId}&api_key=${apiKey}&${ckcs}`)
    .then((res) => setIsLoading(false))
    .catch((err) => setIsLoading(false));
};
const postZipcodeGroup = (
  id,
  group,
  loading,
  setRefreshItems,
  refreshItems,
  setCreateProduct,
  setEditObj,
  successMsg,
  setEditProduct,
  resp
) => {
  loading(true);
  let url = `${devUrl}/zipcode_update?api_key=${apiKey}&${ckcs}&productid=${id}&zipcode_group=${group}`;
  axios
    .get(url)
    .then((res) => {
      loading(false);
      setRefreshItems(!refreshItems);
      if (resp.data.type === "variable") {
      } else {
        setEditObj({});
        setEditProduct(false);
        setCreateProduct(false);
      }

      Swal.fire({
        text: successMsg,
        icon: "success",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          setSkuInWordpress(loading, id);
        }
      });
    })
    .catch((err) => {
      loading(false);
      Swal.fire({
        text:
          err.response?.message || err?.message || err?.response?.data?.message,
        icon: "error",
        allowOutsideClick: false,
      });
    });
};
export const getProductZipcode = (id, zip, setZipcodeGroup, setIsLoading) => {
  setIsLoading(true);
  let zipcodes = [...zip];
  let url = `${devUrl}/zipcode_get?api_key=${apiKey}&productid=${id}&${ckcs}`;
  axios
    .get(url)
    .then((res) => {
      let productZip = [];
      if (res.data instanceof Array) {
        res = res.data.toString().split("");
      } else {
        res.data = res.data.toString().split("");
      }
      zipcodes.map((element) => {
        for (let i = 0; i < zipcodes.length - 1; i++) {
          res?.data.map((zip) => {
            if (element.value == zip) {
              productZip.push(element);
            }
          });
        }
      });
      let pp = productZip.filter(
        (ele, ind) =>
          ind ===
          productZip.findIndex(
            (elem) => elem.value === ele.value && elem.label === ele.label
          )
      );
      setZipcodeGroup(pp);
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};
