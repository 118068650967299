import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { getUsers, moviDb, auth } from "../firebase/index";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import * as actions from "../actions/Products";
import { FaEdit, FaPlus, FaMinus, FaFilter, FaTrashAlt } from "react-icons/fa";
import Popup from "./common/Popup";
import Progress from "./common/Progress";
import { Drawer, TablePagination } from "@material-ui/core";
import Divider from "../assets/Sort.svg";
import SortIcons from "./common/SortIcons";
import Swal from "sweetalert2";
import Loader from "../common/loader/loader";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Select from "react-select";
import projectContext from "../context/App.Context";

const dropdown = [
  { name: "Customer", field: "customer" },
  { name: "Call Type", field: "call_type" },
  { name: "Follow Up", field: "follow_up" },
];

function CallLogs(props) {
  const {
    sidebarStatus
  } = useContext(projectContext);
  const [callLogsList, setCallLogsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [progress, setProgress] = useState(false);
  const [editcallLogObj, setEditcallLogObj] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sorting, setSorting] = useState({
    field: "call_date_time",
    order: "asc",
  });
  const [up, setUp] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [callLogs, setCallLogs] = useState([]);
  const [showmenu, setShowMenu] = useState(false);
  const dropdownRef = useRef();
  const [state, setState] = React.useState({
    left: false,
  });
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFollowUp, setSelectedFollowUp] = useState(null);
  const handleFilterSelectedType = (type) => {
    setSelectedFollowUp(null);
    setSelectedType(type);
    getFilteredCalllogs("call_type", type.value);
  };
  const handleFilterSelectedFollow = (followup) => {
    setSelectedType(null);
    setSelectedFollowUp(followup);
    getFilteredCalllogs("follow_up", followup.value);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleCallLogChange = (e) => {
    setProgress(true);
    setCallLogsList((callLogsList) => [
      ...callLogsList.filter(function (item) {
        return item.id != e.id;
      }),
      e,
    ]);
    setProgress(false);
    setIsEdit(false);
  };

  const togglePopup = (open) => {
    setIsOpen(!isOpen);
    setIsEdit(false);

    if (isOpen) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  };

  const handleEdit = (editItemId) => {
    setIsEdit(!isEdit);
    setEditcallLogObj(editItemId);
    setIsOpen(!isOpen);
  };
  const handleDelete = (deleteItemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setProgress(true);
        const document = moviDb.doc("call_log/" + deleteItemId);
        document
          .get()
          .then((doc) => {
            if (!doc.exists) {
              //return response.status(404).json({ error: 'Todo not found' })
            }
            return document.delete();
          })
          .then(() => {
            //response.json({ message: 'Delete successfull' });
            setProgress(false);
            let filteredArray = callLogsList.filter(function (item) {
              return item.id !== deleteItemId;
            });
            setCallLogsList(filteredArray);
            Swal.fire({
              text: "deleted successfully",
              icon: "success",
            }).then((res) => {
              if (res.isConfirmed) {
                getCallLogs();
              }
            });
          })
          .catch((err) => {
            Swal.fire({
              text: "Failed to delete",
              icon: "error",
              title: "Error",
            });
          });
      }
    });
  };

  useEffect(() => {
    getCallLogs();
  }, []);
  const getCallLogs = () => {
    setProgress(true);
    setSelectedFollowUp(null);
    setSelectedType(null);
    moviDb
      .collection("call_log")
      .get()
      .then((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
        setCallLogsList(postData);
        setCallLogs(postData);
        setProgress(false);
      });
  };
  const getFilteredCalllogs = (k, value) => {
    setProgress(true);
    moviDb
      .collection("call_log")
      .where(k, "==", value)
      .get()
      .then((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
        setCallLogsList(postData);
        setCallLogs(postData);
        setProgress(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const options = [
    { value: "General Enquiry", label: "General Enquiry" },
    { value: "Order", label: "Order" },
    { value: "New Product Request", label: "New Product Request" },
    { value: "Website", label: "Website" },
    { value: "Mobile App", label: "Mobile App" },
    { value: "Payment", label: "Payment" },
    { value: "Business", label: "Business" },
    { value: "Other", label: "Other" },
  ];
  const followOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const headerData = [
    {
      name: "Customer",
      field: "customer",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Message",
      field: "order_total",
      image: Divider,
      numeric: true,
      sortable: false,
    },
    {
      name: "Type",
      field: "call_type",
      image: Divider,
      numeric: false,
      sortable: false,
    },
    {
      name: "Phone",
      field: "customer",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Follow Up",
      field: "follow_up",
      image: Divider,
      numeric: true,
      sortable: false,
    },
    {
      name: "Order No",
      field: "order_number",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Date",
      field: "call_date_time",
      image: Divider,
      numeric: false,
      sortable: true,
    },
    {
      name: "Actions",
      field: "payment_status",
      numeric: true,
      sortable: false,
    },
  ];
  const commentsData = useMemo(() => {
    let computedComments = callLogsList;
    if (sorting.field) {
      computedComments = computedComments.sort((a, b) => {
        if (a[sorting.field] < b[sorting.field]) {
          return sorting.order === "asc" ? 1 : -1;
        } else if (a[sorting.field] > b[sorting.field]) {
          return sorting.order === "asc" ? -1 : 1;
        }
      });
    }
  }, [sorting, callLogsList]);
  const filterItems = [
    "customer",
    "phone",
    "call_type",
    "call_message",
    "call_date_time",
  ];
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const arr = [...callLogs];
    let newArray = arr.filter((item) => {
      return filterItems.some((key) =>
        item[key].toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setCallLogsList(newArray);
  };
  const useStyles = makeStyles({
    list: {
      width: 250,
      padding: "20px",
    },
    fullList: {
      width: "auto",
    },
  });
  const classes = useStyles();
  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />

        <div
          className={
            sidebarStatus ? "main-panel" : "main-panel width-main-panel"
          }
        >
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    {isOpen && (
                      <Popup
                        isEdit={isEdit}
                        editcallLogObj={editcallLogObj}
                        handleClose={togglePopup}
                        handleCallLogChange={handleCallLogChange}
                        getCallLogs={getCallLogs}
                        progress={progress}
                        setProgress={setProgress}
                      />
                    )}
                    {progress && <Loader />}

                    <h4
                      className="card-title header-filter"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Call Logs
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                        className="marginTop-10"
                      >
                        <FaFilter
                          className="filterIcon"
                          style={{ cursor: "pointer", marginRight: "30px" }}
                          onClick={toggleDrawer("left", true)}
                        />
                        <Drawer
                          anchor={"left"}
                          open={state["left"]}
                          style={{ width: "200px" }}
                        >
                          {
                            <div
                              className={clsx(classes.list, {
                                [classes.fullList]:
                                  "left" === "top" || "left" === "bottom",
                              })}
                            >
                              <h4>Call Types</h4>
                              <Select
                                options={options}
                                placeholder="Select Call Type"
                                className="abc"
                                name="call_type"
                                value={selectedType}
                                onChange={handleFilterSelectedType}
                              />
                              <div className="status_filter">
                                <h4>Follow Up</h4>
                                <Select
                                  options={followOptions}
                                  className="abc"
                                  placeholder="Select Follow Up"
                                  name="follow_up"
                                  value={selectedFollowUp}
                                  onChange={handleFilterSelectedFollow}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "10px",
                                }}
                              >
                                <button
                                  onClick={getCallLogs}
                                  className="filter_buttons_submit"
                                >
                                  Clear Filter
                                </button>
                                <button
                                  className="filter_buttons_clear"
                                  onClick={toggleDrawer("left", false)}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          }
                        </Drawer>
                        <div className="input-group" style={{ width: "400px" }}>
                          <div
                            className="input-group-prepend hover-cursor"
                            id="navbar-search-icon"
                          >
                            <span className="input-group-text" id="search">
                              <i className="icon-search" />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="navbar-search-input"
                            placeholder="Search now"
                            aria-label="search"
                            aria-describedby="search"
                            value={searchValue}
                            onChange={handleSearch}
                          />
                        </div>
                        <FaPlus
                          className="filterIcon"
                          onClick={() => togglePopup()}
                          style={{ cursor: "pointer", float: "right" }}
                        />
                      </div>
                    </h4>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {headerData.map((item, index) => (
                              <th key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.name}
                                  {item.sortable ? (
                                    <SortIcons
                                      up={up}
                                      setUp={setUp}
                                      sorting={sorting}
                                      setSorting={setSorting}
                                      item={item}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {callLogsList &&
                            callLogsList
                              .slice(
                                (page + 1 - 1) * rowsPerPage,
                                (page + 1) * rowsPerPage
                              )
                              .map((callLog, key) => {
                                return (
                                  <tr key={key}>
                                    <td data-label="Customer">
                                      {callLog.customer}
                                    </td>
                                    <td data-label="Message">
                                      {callLog.call_message}
                                    </td>
                                    <td data-label="Type">
                                      {callLog?.call_type}
                                    </td>
                                    <td data-label="Phone">{callLog.phone}</td>
                                    <td data-label="Follow Up">
                                      {callLog.follow_up ? "Yes" : "No"}
                                    </td>
                                    <td data-label="Order No">
                                      {callLog.order_number}
                                    </td>
                                    <td data-label="Date">
                                      {callLog.call_date_time}
                                    </td>
                                    <td data-label="Action">
                                      &nbsp;
                                      <FaEdit
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleEdit(callLog)}
                                      />
                                      &nbsp;&nbsp;&nbsp;
                                      <FaTrashAlt
                                        style={{ cursor: "pointer" }}
                                        title="Delete"
                                        onClick={() =>
                                          Swal.fire({
                                            title:
                                              "Do you want to Delete the Call log?",
                                            showDenyButton: true,
                                            showCancelButton: false,
                                            confirmButtonText: `Delete`,
                                            denyButtonText: `Cancel`,
                                            confirmButtonColor: "#39f",
                                            icon: "info",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              handleDelete(callLog.id);
                                            }
                                          })
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                    <TablePagination
                      component="div"
                      count={callLogsList?.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:../../partials/_footer.html */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Copyright © 2021{" "}
                <a href="https://myonlinevipani.com" target="_blank">
                  My Online Vipani
                </a>
                . All rights reserved.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Developed and maintained by{" "}
                <a href="https://butspro.com" target="_blank">
                  BUTSPRO IT LLP
                </a>
              </span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    productList: state.Products.productsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProductList: () => dispatch(actions.getClientList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLogs);
